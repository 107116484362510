import { useEffect, useState } from "react";
import { Box, Container, Text, Link } from "@chakra-ui/react";
import asinantes from "./asinantes";
import ManifestoGl from "./manifesto-gl";
import ManifestoEs from "./manifesto-es";
import ManifestoEn from "./manifesto-en";
import ScrollToBottomButton from "~/src/components/scroll-to-bottom-button";
import MetaForSharing from "~/src/components/meta-for-sharing";
import manifestoGrupoImage from "~/public/manifesto-grupo.png";
import Papa from "papaparse";

const Sharing = ({ currentLang }) => {
  const title = i18nStringFn({
    currentLang: currentLang,
    gl: "Asina o Manifesto Acibal",
    es: "Firma el Manifiesto Acibal",
    en: "Sign the Acibal Manifesto",
  });

  const description = i18nStringFn({
    currentLang: currentLang,
    gl: "Un Manifesto contra o espolio dos nosos montes e os seus bens.",
    es: "Un Manifiesto contra el expolio de nuestros montes y sus bienes.",
    en: "A Manifesto against the plundering of Galician mountains and their assets.",
  });

  return (
    <MetaForSharing
      title={title}
      description={description}
      imageURL={manifestoGrupoImage}
    />
  );
};

const ColumnText = ({ text, isBold, right }) => (
  <Text
    fontSize={isBold ? "lg" : "md"}
    fontWeight={isBold ? "normal" : "thin"}
    lineHeight={isBold ? "taller" : null}
    align="left"
  >
    {text}
  </Text>
);

const SignNowButton = ({ currentLang, total }) => {
  const text = (
    <I18nString
      currentLang={currentLang}
      gl={`Xa hai ${total} apoios. Asina ti tamén! ✍🏻`}
      es={`Ya hay ${total} apoyos. Firma tú también! ✍🏻`}
      en={`We have ${total} supporters already. Sign now! ✍🏻`}
    />
  );
  return <ScrollToBottomButton text={text} />;
};

const normalizedChar = (text) =>
  text?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

const NamesList = ({ names, importantSignatures }) => {
  let allSortedNames = names
    .concat(importantSignatures)
    .sort((a, b) => a.nome.localeCompare(b.nome));

  return (
    <Box maxW="container.lg" marginX="auto" padding="4">
      {allSortedNames.map(({ nome, cargo }, index) => {
        return (
          <>
            {normalizedChar(nome[0]) !==
              normalizedChar(allSortedNames?.[index - 1]?.nome[0]) && (
              <Box textAlign="right" color="#ccc" fontWeight="extrabold">
                {nome[0]}
              </Box>
            )}
            <Box marginBottom="4">
              <ColumnText text={nome} isBold />
              <ColumnText text={cargo} />
            </Box>
          </>
        );
      })}
    </Box>
  );
};

const ManifestoText = ({ lang = "gl" }) => {
  const Manifesto = {
    en: ManifestoEn,
    es: ManifestoEs,
    gl: ManifestoGl,
  }[lang];

  return <Manifesto />;
};

const i18nStringFn = ({ currentLang, es, en, gl }) =>
  ({ en, es, gl }[currentLang]);

const I18nString = ({ currentLang, es, en, gl }) => {
  return { en, es, gl }[currentLang];
};

const LangSelector = ({ currentLang }) => {
  return (
    <>
      <Link
        href="/manifesto-acibal"
        mr={2}
        fontWeight={currentLang === "gl" ? "bold" : "light"}
      >
        Galego
      </Link>
      {" | "}
      <Link
        href="/manifesto-acibal/es"
        mr={2}
        fontWeight={currentLang === "es" ? "bold" : "light"}
      >
        Castellano
      </Link>
      {" | "}
      <Link
        href="/manifesto-acibal/en"
        fontWeight={currentLang === "en" ? "bold" : "light"}
      >
        English
      </Link>
    </>
  );
};

export const Manifesto = ({ lang = "gl" }) => {
  const SIGNATURES_URL =
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vTZ04w6nKlbnXQlcIHvnw8Y6WxdtVVxBR9p3Z1GauKOGgm4cBnCs6l-p53v62L0iwBDmNG2QjFLQV_d/pub?gid=1664652835&single=true&output=csv";

  const [signatures, setSignatures] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getImportant = (signatures) =>
    signatures.filter((s) => s.importante === "TRUE");

  const asinantesImportantes = getImportant(signatures);
  const outrosAsinantes = signatures.filter((s) => s.nome);

  const others = outrosAsinantes.length - asinantesImportantes.length;

  const total = outrosAsinantes.length + asinantes.length;

  useEffect(() => {
    // Your Tally script here
    var d = document,
      w = "https://tally.so/widgets/embed.js",
      v = function () {
        "undefined" != typeof Tally
          ? Tally.loadEmbeds()
          : d
              .querySelectorAll("iframe[data-tally-src]:not([src])")
              .forEach(function (e) {
                e.src = e.dataset.tallySrc;
              });
      };
    if ("undefined" != typeof Tally) v();
    else if (d.querySelector('script[src="' + w + '"]') == null) {
      var s = d.createElement("script");
      (s.src = w), (s.onload = v), (s.onerror = v), d.body.appendChild(s);
    }
  }, []);

  useEffect(() => {
    Papa.parse(SIGNATURES_URL, {
      download: true,
      header: true,
      complete: (results) => {
        setSignatures(results.data);
        setIsLoading(false);
      },
    });
  }, []);

  return (
    <>
      <Container maxW="700px" textAlign="center" mt="6">
        <SignNowButton currentLang={lang} total={total} />
        <Text
          as="h1"
          fontSize="5xl"
          fontWeight="thin"
          marginBottom="2"
          fontFamily="Alegreya, serif"
        >
          <I18nString
            currentLang={lang}
            gl="MANIFESTO ACIBAL"
            es="MANIFIESTO ACIBAL"
            en="ACIBAL MANIFESTO"
          />
        </Text>
        <Text mb="16" fontSize="xs" fontWeight="normal" fontFamily="mono">
          <LangSelector currentLang={lang} />
        </Text>
        <Text
          as="h2"
          fontSize="4xl"
          fontWeight="bold"
          mb="8"
          fontFamily="Oswald"
        >
          <I18nString
            currentLang={lang}
            gl="MANIFESTO CONTRA O ESPOLIO DOS NOSOS MONTES E OS SEUS BENS"
            es="MANIFIESTO CONTRA EL EXPOLIO DE LOS MONTES DE GALICIA Y SUS BIENES"
            en="MANIFESTO AGAINST THE PLUNDERING OF GALICIAN MOUNTAINS AND THEIR ASSETS"
          />
        </Text>
        <ManifestoText lang={lang} />
        <Box border="1px solid #aaa" mt="16" p="8">
          <Text as="p" fontSize="md" align="center" fontWeight="normal">
            <I18nString
              currentLang={lang}
              gl="Este manifesto foi impulsado polo colectivo Amil SEN Eólicos e o Colectivo pola defensa do Patrimonio A Forneiriña a raíz do que está a acontecer no monte Acibal. Foi presentado o 28 de Outubro de 2023"
              es="Este manifiesto ha sido impulsado por el colectivo Amil SEN Eólicos y el Colectivo por la defensa del Patrimonio A Forneiriña, a raíz de los acontenimientos en el monte Acibal. Fue presentado el 28 de Octubre de 2023"
              en="This manifesto has been promoted by the collective Amil SEN Eólicos and the Collective for the Defense of Heritage A Forneiriña, in response to the events in Mount Acibal. It was presented on October 28, 2023"
            />
          </Text>
          <Text as="p" fontSize="md" mt="8" align="center" fontWeight="light">
            <I18nString
              currentLang={lang}
              gl="Se es unha asociación ou profesional e queres apoiar este manifesto, e aparecer nesta primeira listaxe contáctanos:"
              es="Si eres una asociación o profesional y quieres apoyar este manifiesto y aparecer en esta primera lista contáctanos:"
              en="If you are an association or professional and want to support it and appear on this firt list, contact:"
            />{" "}
            <a
              href="mailto:manifesto@amilseneolicos.org"
              style={{ color: "#999", fontWeight: "bold" }}
            >
              manifesto@amilseneolicos.org
            </a>
          </Text>
          <Text as="p" fontSize="md" mt="8" align="center" fontWeight="light">
            <I18nString
              currentLang={lang}
              gl="Se es un particular, farto da situación de indefensión que vivimos ante macroproxectos mineiros e eólicos, apoíanos no formulario ao final da páxina."
              es="Si eres un particular, harto de la situación de indefensión que vivimos ante macropoyectos mineros y eólicos apóyanos en formulario al final de esta página."
              en="If you are an individual, tired of the helplessness we experience in the face of large mining and wind projects, support us through the form on the bottom of the page."
            />{" "}
          </Text>
        </Box>
        <Box borderBottom="1px solid black" mt="16">
          <Text
            as="h1"
            fontSize="3xl"
            align="center"
            mb="2"
            fontFamily="Alegreya, serif"
          >
            <I18nString
              currentLang={lang}
              gl="ASINANTES"
              es="FIRMANTES"
              en="SUPPORTERS"
            />
          </Text>
        </Box>
      </Container>
      <Container maxW="800px" textAlign="center" mt="6" mb="8">
        <NamesList
          names={asinantes}
          importantSignatures={asinantesImportantes}
        />
        {!isLoading && (
          <Text as="p" fontSize="xl" align="left" mb="4" id="bottom">
            <Link
              href="/manifesto-acibal/asinantes"
              textDecoration="underline"
              _hover={{ color: "#999" }}
            >
              <I18nString
                currentLang={lang}
                gl={`... e un total de ${total} sinaturas entre persoas e colectivos, consulta a listaxe completa.`}
                es={`... y un total de ${total} firmas entre personas y colectivos, consulta el listado completo.`}
                en={`... ana ${total} signatures in total, click here to view the rest of them. `}
              />
            </Link>
          </Text>
        )}
        <Box borderBottom="1px solid black" mt="16">
          <Text
            as="p"
            fontSize="3xl"
            align="center"
            fontFamily="Alegreya, serif"
            id="bottom"
            mb="0"
          >
            <I18nString
              currentLang={lang}
              gl="ASINA TÍ TAMÉN AQUÍ"
              es="FIRMA TÚ TAMBIÉN AQUÍ"
              en="SUPPORT OUR MANIFESTO HERE"
            />
          </Text>
        </Box>
      </Container>
      <Container maxW="800px" id="asinar">
        {lang === "en" ? (
          <iframe
            data-tally-src="https://tally.so/embed/wQR9qY?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
            loading="lazy"
            width="100%"
            height="392"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="Manifesto Acibal (eng)"
          ></iframe>
        ) : (
          <iframe
            data-tally-src="https://tally.so/embed/mRoQAJ?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
            loading="lazy"
            width="100%"
            height="392"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="Manifesto Acibal"
          ></iframe>
        )}
      </Container>
      <Sharing currentLang={lang} />
    </>
  );
};
