import { alegaciones, introducciones, solicitudes } from "./alegaciones";
import Allegations from "~/src/components/signatures/allegations";

export const AlegaLatTourinhanTivo = () => (
  <Allegations
    polygonName="Modificación da solicitude de declaración de utilidade pública da liña de alta tensión 66/220 kV entre SET parque eólico Pico Touriñán-SET Tivo (expediente IN407A 2011/350)"
    polygonShortName="LAT Touriñán-Tivo"
    alegaciones={alegaciones}
    introducciones={introducciones}
    solicitudes={solicitudes}
    minAlegaciones={5}
  />
);
