import {
  Box,
  chakra,
  Container,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Flex,
  Link,
} from "@chakra-ui/react";
import { FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import sections from "../partials/sections";
import Logo from "../partials/logo";

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded="full"
      w={8}
      h={8}
      cursor="pointer"
      as="a"
      href={href}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      transition="background 0.3s ease"
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function SmallCentered() {
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
      w="100%"
      mt="50"
    >
      <Container
        as={Stack}
        maxW="6xl"
        py={4}
        spacing={4}
        justify="center"
        align="center"
      >
        <Logo />
        <Flex
          w="100%"
          direction={{ base: "column", md: "row" }}
          align="center"
          justify="center"
        >
          {sections.map((section) => (
            <Link href={section.to} px={[0, 6]} py={[2, 6]}>
              {section.name}
            </Link>
          ))}
        </Flex>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle="solid"
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        <Container
          as={Stack}
          maxW="6xl"
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ base: "center", md: "space-between" }}
          align={{ base: "center", md: "center" }}
        >
          <Text as="i" color="#999" justify="center" align="center">
            Contido elaborado polas veciñas e veciños de Amil:{" "}
            <Link href="mailto:contacto@amilseneolicos.org">
              contacto@amilseneolicos.org
            </Link>
          </Text>
          <Stack direction="row" spacing={6}>
            <SocialButton
              label="Twitter"
              href="http://twitter.com/AmilSenEolicos"
            >
              <FaTwitter />
            </SocialButton>
            <SocialButton
              label="YouTube"
              href="http://youtube.com/playlist?list=PLOHPqAeAZhimpuCY-D1bAlRzp9LJUZ1tX"
            >
              <FaYoutube />
            </SocialButton>
            <SocialButton
              label="Instagram"
              href="http://instagram.com/amilseneolicos"
            >
              <FaInstagram />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
