import React, { useState, useEffect } from "react";
import { Button } from "@chakra-ui/react";
import "./scroll-to-bottom-button.css";

function ScrollButton({ top, text }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const targetPosition = top
        ? 100
        : document.documentElement.scrollHeight - window.innerHeight - 200;

      setIsVisible(
        scrollPosition > 200 && scrollPosition < targetPosition - 500
      );
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [top]);

  const scrollToTopOrBottom = () => {
    if (top) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      const bottom = document.getElementById("bottom");

      if (bottom) {
        const topOffset = bottom.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: topOffset, behavior: "smooth" });
      } else {
        window.scrollTo({
          top: document.body.scrollHeight - window.innerHeight * 1.8,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <Button
      className={`scroll-button ${isVisible ? "visible" : ""}`}
      onClick={scrollToTopOrBottom}
      position="fixed"
      bottom="20px"
      right="20px"
      pl="4"
      pr="4"
      pt="4"
      pb="4"
      fontSize="xl"
      fontWeight="normal"
      colorScheme="primary"
      border="none"
      borderRadius="5px"
      cursor="pointer"
      zIndex="999"
      boxShadow="md" // Add a medium shadow when not hovered or active
      _active={{
        boxShadow: "lg", // Add an even larger shadow on click
      }}
    >
      {text ? text : top ? "Scroll To Top" : "Scroll To Bottom"}
    </Button>
  );
}

export default ScrollButton;
