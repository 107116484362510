import {
  Box,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
  Button,
} from "@chakra-ui/react";
import { GoLocation } from "react-icons/go";
import { TbWindmillOff } from "react-icons/tb";
import { BsCalendarWeekFill } from "react-icons/bs";
import { FaFileSignature } from "react-icons/fa";
import BaseSection from "~/src/components/partials/base-section";
import FileList from "~/src/components/partials/file-list";
import Description from "~/src/components/partials/description";
import Link from "~/src/components/partials/link";

const StatsCard = (props) => {
  const { title, stat, icon } = props;
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={"5"}
      shadow={"md"}
      border={"1px solid"}
      borderColor={useColorModeValue("gray.800", "gray.500")}
      rounded={"lg"}
    >
      <Flex justifyContent={"space-between"}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontWeight={"medium"} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
            {stat}
          </StatNumber>
        </Box>
        <Box
          my={"auto"}
          color={useColorModeValue("gray.800", "gray.200")}
          alignContent={"center"}
        >
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
};

function ZudreiroInfo() {
  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing="4">
      <Box p="4">
        <SimpleGrid columns={{ base: 1 }} spacing={{ base: 5 }}>
          <StatsCard
            title="Aeroxeneradores"
            stat="5 de 200m de altura"
            icon={<TbWindmillOff size={"3em"} />}
          />
          <StatsCard
            title={"Localización"}
            stat={"Moraña/Campo Lameiro"}
            icon={<GoLocation size={"3em"} />}
          />
          <StatsCard
            title={"Alegacións"}
            stat={"Ata o 19 de Abril"}
            icon={<BsCalendarWeekFill size={"3em"} />}
          />
        </SimpleGrid>
      </Box>
      <Box p="8">
        <iframe
          src="https://www.google.com/maps/d/embed?mid=12LTxh99qrFDT8a6LXEAPFNxLBAN4NS4&ehbc=2E312F"
          width="100%"
          height="400px"
        ></iframe>
      </Box>
    </SimpleGrid>
  );
}

const AllegationInfo = () => (
  <BaseSection
    title="Alegacións a Zudreiro"
    explanation={
      <>
        Aquí atoparás todos os modelos de alegación dos que dispoñemos para
        zudreiro.
        <br />
        Pódelas presentar en calquera rexistro, no teu concello, na Xunta, na
        subdelegación do Goberno...
        <br />
        Se tes certificado dixital, tamén as podes presentar online, se non
        sabes como sigue{" "}
        <Link href="https://drive.google.com/drive/u/0/folders/1ChB1gJ9rmVNg9fRNzEP1rkJodq7MfqaM">
          esta guía
        </Link>
        , é moi doado.
      </>
    }
  >
    <FileList folderId="1lonojlR0XR7iolgoClL-3zMd2vqYsmX3" mb="10" />

    <Description>
      Podes presentar calquera delas, só hai varios modelos para que non sexan
      todas iguais
      <br />
      O ideal é que descargues use un dos .doc e engadas o que che apeteza
      alegar.
      <br />
      Tamén podes usar como base unhas moi completas coma as de{" "}
      <Link href="https://valfluvialdolouridocorcoesto.com/2023/03/16/conece-o-proxecto-eolico-zudreiro-nos-municipios-de-morana-e-campo-lameiro-pontevedra-descarga-o-modelo-de-alegacion-e-participa/">
        Petón do Lobo
      </Link>{" "}
      e as aumentes co que tí consideres.
    </Description>
  </BaseSection>
);

const Online = () => (
  <BaseSection title="Qué facer se non podo imprimilas?" mt={16}>
    <Flex flexDirection="column" pb={20} w="100%">
      <Description>
        Se non podes imprimir as alegacións ou estás fora de España, pódelas
        firmar online dende esta páxina web, descargarase un PDF firmado que
        podes enviarnos a:{" "}
        <Link href="mailto:alegacions@amilseneolicos.org">
          alegacions@amilseneolicos.org
        </Link>
      </Description>

      <Link href="zudreiro/alega">
        <Button w="100%" size="lg" colorScheme="orange">
          ALEGA AQUÍ!
        </Button>
      </Link>
    </Flex>
  </BaseSection>
);

const DueDate = () => (
  <BaseSection title="Qué facer se pasou o Prazo?" mt={16}>
    <Flex flexDirection="column" w="100%">
      <Description>
        Podes entregalas todavía, só tes que poñer no asunto:{" "}
        <strong>"POR DEFECTOS NA TRAMITACIÓN ..."</strong>e seguir co asunto
        orixinal
      </Description>
    </Flex>
  </BaseSection>
);

export const HomeZudreiro = () => (
  <Flex flexDirection="column" px={16}>
    <ZudreiroInfo />
    <AllegationInfo />
    <DueDate />
    <Online />
  </Flex>
);
