import { useState, useEffect } from "react";
import {
  Container,
  Link,
  Button,
  List,
  ListItem,
  Image,
  ListIcon,
  Skeleton,
  Center,
  Text,
  Box,
} from "@chakra-ui/react";
import Papa from "papaparse";
import asinantes from "./asinantes";

const SIGNATURES_URL =
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vTZ04w6nKlbnXQlcIHvnw8Y6WxdtVVxBR9p3Z1GauKOGgm4cBnCs6l-p53v62L0iwBDmNG2QjFLQV_d/pub?gid=1664652835&single=true&output=csv";

const EMPTY_SIGNATURE_URL = "https://i.ibb.co/hf79pzM/firma.png";

const normalizedChar = (text) =>
  text
    ?.normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toUpperCase();

export const Signatures = () => {
  const [signatures, setSignatures] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Papa.parse(SIGNATURES_URL, {
      download: true,
      header: true,
      complete: (results) => {
        setSignatures(
          results.data
            .filter((s) => s.nome)
            .sort((a, b) => a.nome.localeCompare(b.nome))
        );
        setIsLoading(false);
      },
    });
  }, []);

  return (
    <Container maxW="700px" textAlign="center" mt="6">
      <Text
        as="p"
        fontSize="2xl"
        align="center"
        fontWeight="thin"
        id="bottom"
        mb="10"
        pb="2"
        borderBottom="1px solid black"
      >
        Xa firmaron o Manifesto Acibal{" "}
        <b>
          {isLoading ? "..." : signatures.length + asinantes.length} persoas e
          colectivos.
        </b>
        <br />
        Gracias a todas e cada unha de vos!
      </Text>
      <List spacing={3} mb="45">
        {isLoading
          ? Array.from({ length: 5 }).map((_, index) => (
              <Center>
                <ListItem key={index}>
                  <Center>
                    <Skeleton height="25px" w="300px" mb="2" />
                  </Center>
                  <Center>
                    <Skeleton height="100px" w="200px" />
                  </Center>
                </ListItem>
              </Center>
            ))
          : // Render the list when data is available
            signatures.map((signature, index) => (
              <>
                {normalizedChar(signature.nome[0]) !==
                  normalizedChar(signatures?.[index - 1]?.nome[0]) && (
                  <ListItem key={signature.nome[0]}>
                    <Box
                      textAlign="right"
                      color="#eee"
                      fontWeight="extrabold"
                      fontSize="9xl"
                      mb="-200"
                    >
                      {signature.nome[0]}
                    </Box>
                  </ListItem>
                )}

                <ListItem key={index}>
                  <div style={{ textAlign: "center", marginTop: "32px" }}>
                    <Text
                      fontSize="lg"
                      mt="8"
                      style={{ textTransform: "capitalize", display: "inline" }}
                    >
                      {signature.nome}
                    </Text>
                    {signature.cargo && signature.cargo !== "-" && (
                      <Text
                        fontSize="md"
                        mt="8"
                        fontWeight="thin"
                        style={{
                          textTransform: "capitalize",
                          display: "inline",
                        }}
                      >
                        {` - ${signature.cargo}`}
                      </Text>
                    )}
                  </div>
                  <ListIcon
                    as={Image}
                    src={
                      signature.firma ? signature.firma : EMPTY_SIGNATURE_URL
                    }
                    w={200}
                    h={100}
                    mt="4"
                  />
                </ListItem>
              </>
            ))}
      </List>{" "}
      <Link href="/manifesto-acibal">
        <Button
          bottom="20px"
          right="20px"
          pl="4"
          pr="4"
          pt="4"
          pb="4"
          mb="25"
          mt="25"
          fontSize="xl"
          fontWeight="normal"
          colorScheme="primary"
          border="none"
          borderRadius="5px"
          cursor="pointer"
          boxShadow="md" // Add a medium shadow when not hovered or active
          _active={{
            boxShadow: "lg", // Add an even larger shadow on click
          }}
        >
          ⬅️ Voltar ao manifesto
        </Button>
      </Link>
    </Container>
  );
};
