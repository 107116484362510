import { Link as ChakraLink } from "@chakra-ui/react";

const Link = (props) => (
  <ChakraLink
    color="primary.900"
    fontWeight="bold"
    textDecoration="underline"
    {...props}
  />
);

export default Link;
