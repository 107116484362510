import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./error-page";
import {
  Root,
  Home,
  Zudreiro,
  Acibal,
  Andurina,
  Map,
  Documentation,
  Videos,
  AlegaZudreiro,
  HomeZudreiro,
  AlegaLatTourinhanTivo,
  HomeLatTourinhanTivo,
  Petroglyphs,
  DogEolicos,
  PorquinhoSolidario,
  Manifesto,
  Gdpr,
  GdprEn,
  Signatures,
  Historia,
  NovoMapa,
} from "./routes";
import { ChakraProvider } from "@chakra-ui/react";
import customTheme from "./utils/theme";
import { inject } from "@vercel/analytics";

// Inject analytics
inject();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "acibal",
        element: <Acibal />,
      },
      {
        path: "andurina",
        element: <Andurina />,
      },
      {
        path: "zudreiro",
        element: <Zudreiro />,
        children: [
          { index: true, element: <HomeZudreiro /> },
          { path: "alega", element: <AlegaZudreiro /> },
        ],
      },
      {
        path: "documentacion",
        element: <Documentation />,
      },
      {
        path: "mapa",
        element: <Map />,
      },
      {
        path: "novo-mapa",
        element: <NovoMapa />,
      },
      {
        path: "petroglifos",
        element: <Petroglyphs />,
      },
      {
        path: "porquinho-solidario",
        element: <PorquinhoSolidario />,
      },
      {
        path: "gdpr",
        element: <Gdpr />,
      },
      {
        path: "gdpr/en",
        element: <GdprEn />,
      },
      {
        path: "manifesto-acibal/asinantes",
        element: <Signatures />,
      },
      {
        path: "manifesto-acibal",
        element: <Manifesto />,
      },
      {
        path: "manifesto-acibal/es",
        element: <Manifesto lang="es" />,
      },
      {
        path: "manifesto-acibal/en",
        element: <Manifesto lang="en" />,
      },
      {
        path: "historia",
        element: <Historia />,
      },
      {
        path: "dog-eolicos",
        element: <DogEolicos />,
      },
      {
        path: "videos",
        element: <Videos />,
      },
      {
        path: "lat-tourinhan-tivo",
        children: [
          { index: true, element: <HomeLatTourinhanTivo /> },
          { path: "alega", element: <AlegaLatTourinhanTivo /> },
        ],
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ChakraProvider resetCSS theme={customTheme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>
);
