import { Box, Text, UnorderedList, ListItem } from "@chakra-ui/react";

const ManifestoEs = () => (
  <Box textAlign="justify" fontFamily="Alegreya, serif">
    <Text fontSize="xl" mb="8">
      It is a fact that the industrial sector has lost a lot of weight in
      Galicia (Spain) and there is an urgent need to attract new investments and
      seek future sectors that can bring wealth and employment to our land.
      However, these needs should not mislead us. Economic development must be
      planned and designed in accordance with a global idea of our country that
      includes the major strategies to follow and ensure that these projects
      meet sustainability and environmental respect criteria.
    </Text>

    <Text fontSize="xl" mb="8">
      However, in recent years, in the name of modernity, “green” energy, and
      progress, legislation has consistently favoured the supposed “public
      utility” and entrepreneurial initiatives, systematically neglecting the
      protection of natural, cultural, and landscape heritage. This trend is
      evident in the texts of laws 5/2017 of October 19, which declares the
      public utility of wind farms, and in Law 9/2021 of February 25, which aims
      to simplify administrative processes and support economic revival in
      Galicia.
    </Text>

    <Text fontSize="xl" mb="8">
      We agree that Galicia should not and cannot give up progress and economic
      development. However, we also have a duty to preserve and protect our
      cultural and natural heritage. We believe that it is a mistake to put a
      mortgage on people's heritage, its cultural, natural, and landscape
      resources in favour of an economic and business approach that is poorly
      supervised by public authorities and primarily designed to maximise the
      profits of large energy lobbies and operators.
    </Text>

    <Text fontSize="xl" mb="8">
      The administrations have shown little rigour in preserving the assets and
      resources of our mountains where wind farms are being constructed. This is
      evident in the case of Monte Acibal, where the local community has
      discovered around 50 findings, such as petroglyphs and ethnographic
      elements, in an area that the wind energy company almost considers
      archaeologically "sterile." This area has granite outcrops, which are the
      most common substrates for the petroglyphs, and, due to its topography, it
      is more likely to contain rock art inscriptions, including the rare
      “tetrasqueis” (swastikas), mediaeval crosses, oak trees, and other
      heritage elements since they dominate a large part of the territory.
    </Text>

    <Text fontSize="xl" mb="8">
      Cultural and natural heritage, along with water resources, livestock, and
      forestry, can also serve as economic resources when exploited in a
      sustainable fashion. Wind farms and high-voltage power lines threaten this
      heritage, especially in the case of the mountains of Campo Lameiro, the
      Capital of Rock Art with one of the largest concentrations of petroglyphs
      in Europe. Petroglyphs are archaeological elements that cannot be
      understood without the surrounding landscape, and any modification to this
      landscape, in addition to altering yet undiscovered assets, will
      permanently change an archaeological landscape, compromising the
      possibility of sustainable cultural and tourist management of these
      resources, along with the economic benefits that this form of rural
      tourism can bring to the region and its people.
    </Text>

    <Text fontSize="xl" mb="8">
      The same applies to various habitats in this mountain, including a vast
      wetland area on the plateau, extensive sloping areas dedicated to wood
      production, and significant areas corresponding to the highest parts and
      very rugged terrains that have always been refuges for wolves and other
      animals.
    </Text>

    <Text fontSize="xl" mb="8">
      We are not irresponsibly opposing wind energy generation; we are opposing
      the practices of the companies that have received approval from the
      Galician Government.
    </Text>

    <Text fontSize="xl" mb="8">
      For all these reasons, the undersigned individuals request:
    </Text>
    <UnorderedList textAlign="left" fontSize="xl" mb="8" ml="8">
      <ListItem mb="4">
        The suspension of work at Acibal due to findings and the destruction of
        possible assets resulting from the lack of a detailed study, which leads
        us to request a moratorium on wind energy project authorizations based
        on the principles of precaution and non-regression.
      </ListItem>
      <ListItem mb="4">
        A review of macro-wind farm projects in archaeological areas, improving
        environmental assessment tools, and re-planning Galicia's economic
        development model.
      </ListItem>
      <ListItem mb="4">
        Respect for the sovereignty of municipalities that oppose the
        installation of macro-wind farms in their territories and an end to
        discrimination against rural areas.
      </ListItem>
      <ListItem mb="4">
        Increased commitment from administrations to enhance the value of the
        heritage they possess.
      </ListItem>
      <ListItem mb="4">
        The need to preserve natural spaces to ensure biodiversity.
      </ListItem>
    </UnorderedList>
    <Text as="h3" fontSize="xl" mb="8" textAlign="center">
      Moraña, August 7, 2023
    </Text>
  </Box>
);

export default ManifestoEs;
