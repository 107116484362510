import React from "react";
import VideoList from "../components/sections/video-list";

const videoIds = [
  "RMNDTiX7G2k",
  "PxMNmssJ6yI",
  "shTLdUf8QaM",
  "8jVvKjUse5k",
  "LCfeG5P5KzA",
  "wezQxPyFW5w",
  "VSnwe6KNU2o",
];

export const Videos = () => {
  return <VideoList videoIds={videoIds} />;
};
