import React from "react";
import { Box } from "@chakra-ui/react";

export const Petroglyphs = () => (
  <Box width="100%" height={{ base: "80vh", md: "1000" }}>
    <iframe
      src="https://www.google.com/maps/d/embed?mid=1FJCw26qkv26fRu18pHj4ecjdmkO2r8s&ll=42.526015359183816%2C-8.602731263131886&z=16"
      width="100%"
      height="100%"
    ></iframe>
  </Box>
);
