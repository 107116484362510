const asinantes = [
  { nome: "Adolfo de Abel Vilela.", cargo: "Historiador." },

  {
    nome: "Agustín Azkarate Garai-Olann.",
    cargo:
      "Director Cátedra UNESCO de Paisajes Culturales y Patrimonio. Universidade do País Vasco.",
  },

  {
    nome: "Alexandra Cabana Outeiro.",
    cargo:
      "Profesora e Fundadora da Revista Murguía. Alexandre Peres Vigo. Docente na Universidade da Coruña.",
  },

  {
    nome: "Ana Isabel Filgueiras Rei.",
    cargo:
      "Arqueóloga municipal de Noia, profesora de Xeografía e Historia na UNED.",
  },

  {
    nome: "Ángel Núñez Sobrino.",
    cargo:
      "Profesor de Filosofía, Escritor e Divulgador da obra de Ramón Sobrino Buhigas e Ramón Sobrino Lorenzo-Ruza.",
  },

  { nome: "Antonio Castro Rozados.", cargo: "Arqueólogo." },

  {
    nome: "Antonio Costa Iglesias.",
    cargo:
      "Investigador, membro do Instituto Padre Sarmiento do CSIC, correspondente do Museo de Pontevedra no Morrazo, debuxante arqueolóxico.",
  },

  {
    nome: "Antonio de la Peña Santos.",
    cargo:
      "Arqueólogo membro do Instituto de Estudos Galegos e do Centro de Estudos Históricos de Pontevedra e Académico da Real Academia Catalá de Belas Artes.",
  },

  {
    nome: "Antonio González Millán.",
    cargo: "Director do Museo Valle-Inclán, A Pobra, e historiador.",
  },
  {
    nome: "Antonio González Millán.",
    cargo: "Arturo Regueiro. Filósofo e escritor.",
  },

  { nome: "Asociación Capitán Gosende.", cargo: "Colectivo." },

  {
    nome: "Asociación para a defensa do Patrimonio Cultural Galego (Apatrigal).",
    cargo: "Colectivo.",
  },

  {
    nome: "Bruno Centelles.",
    cargo:
      "Mestre e membro da Xunta de Goberno do Instituto de Estudos Miñoráns.",
  },
  {
    nome: "Bruno Rúa.",
    cargo: "Escritor, filósofo e guía turístico.",
  },

  {
    nome: "Buenaventura Aparicio Casado.",
    cargo: "Doutor en Historia e Antropólogo.",
  },

  { nome: "Carlos Solla.", cargo: "Escritor e docente." },

  {
    nome: "Carlos Baliñas Pérez.",
    cargo:
      "Historiador e profesor da Universidade da Coruña. Carlos López Bernárdez. Crítico de Arte e escritor.",
  },

  { nome: "Carlos Varela Aenlle.", cargo: "Etnógrafo." },

  { nome: "Colectivo Amigos dos Museos de Galicia.", cargo: "Colectivo." },

  { nome: "Damián Porto.", cargo: "Escritor e Arquiveiro." },

  {
    nome: "Dario Seglie.",
    cargo:
      "Profesor, Doutor e Director Xeral do CeSMAP- Study Centre and Prehistoric Art Museum Polytechnic of Torino.",
  },

  {
    nome: "David Barreiro Martínez.",
    cargo:
      "Técnico de investigación do CSIC - Consello Superior de Investigacións Científicas.",
  },

  {
    nome: "David Otero.",
    cargo: "Escritor e Vicepresidente da Fundación Alexandre Bóveda.",
  },

  {
    nome: "Ecoloxistas en Acción Galiza Atlántica E Verde",
    cargo: "Asociación.",
  },

  { nome: "Elena Cabrejas.", cargo: "Arqueóloga." },

  { nome: "Elena Rodríguez Vieito.", cargo: "Activista medio ambiental." },

  { nome: "Eli Ríos.", cargo: "Escritora." },

  { nome: "Emma Pedreira.", cargo: "Escritora." },

  {
    nome: "Encarna Otero Cepeda.",
    cargo: "Activista da cultura e o territorio.",
  },

  { nome: "Felipe Criado Boado.", cargo: "Arqueólogo do INCIPIT-CSIC." },

  {
    nome: "Felipe-Senén López Gómez.",
    cargo: "Arqueólogo e Historiador da Cultura Galega.",
  },

  {
    nome: "Fernando Carrera.",
    cargo: "Arqueólogo, Restaurador especialista en Arte Rupestre.",
  },

  {
    nome: "Fernando Coimbra.",
    cargo: "Profesor no Instituto Politénico de Tomar, Portugal.",
  },

  { nome: "Fernando González Graña.", cargo: "Escritor." },

  { nome: "Fernando Javier Costas Goberna.", cargo: "Investigador." },

  { nome: "Fernando Salgado.", cargo: "Escritor e xornalista." },

  { nome: "Fina Casalderrey Fraga.", cargo: "Mestra e escritora." },

  { nome: "Fran Remiseiro.", cargo: "Escultor." },

  { nome: "Héitor Picallo Fontes.", cargo: "Enxeñeiro Naval e Historiador." },

  {
    nome: "Joaquín Araújo.",
    cargo: "Campesiño, escritor e activista ecolóxico.",
  },

  { nome: "José Casal Porto.", cargo: "Activista cultural." },

  { nome: "José Suárez Otero.", cargo: "Arqueólogo." },

  {
    nome: "Juan Hermida Lago.",
    cargo: "Doutor en Bioloxía e Director do Instituto de Estudos Miñoráns.",
  },

  {
    nome: "Julio Fernández Pintos.",
    cargo: "Arqueólogo especialista en Arte Rupestre.",
  },
  {
    nome: "Júlio Maside Rodríguez.",
    cargo:
      "Vizinho do Acibal, Doutor em Biologia, Professor da Universidade de Santiago.",
  },

  { nome: "Loli Quinteiro.", cargo: "Fotógrafa." },

  { nome: "Manuel Busto Magdalena.", cargo: "Artista plástico." },

  {
    nome: "Manuel Constantino Camba Gayoso.",
    cargo: "Carteiro e membro da AACC Liga Céltiga.",
  },

  {
    nome: "Manuel Ferreiro.",
    cargo: "Escritor e Catedrático da Universidade da Coruña.",
  },

  { nome: "Manuel Rivas.", cargo: "Escritor, guionista e xornalista." },

  {
    nome: "Manuel Santos Estévez.",
    cargo: "Arqueólogo especialista en arte rupestre.",
  },

  {
    nome: "María Antonia Pérez Rodríguez.",
    cargo: "Profesora da Universidade da Coruña.",
  },

  {
    nome: "María del Mar Bóveda López.",
    cargo: "Educadora, especialista na posta en valor do Patrimonio Cultural.",
  },

  {
    nome: "María do Rosário Sousa.",
    cargo: "Profesora do ensino básico na Escola de Chamusca, Portugal.",
  },

  {
    nome: "María do Rosário Sousa.",
    cargo: "María Luisa Álvarez Garea. Activista cultural.",
  },

  {
    nome: "María Silva Gago.",
    cargo: "Doutora en Evolución Humana pola Universidade de Burgos.",
  },

  { nome: "Maribel Iglesias Baldonedo.", cargo: "Historiadora." },

  { nome: "Mateo Fontán.", cargo: "Arqueólogo." },

  { nome: "Miguel Ángel Álvarez Rodríguez.", cargo: "Xornalista e artista." },

  {
    nome: "Mónica Fernandez-Aceytuno.",
    cargo: "Bióloga e Defensora do Monte do Gato.",
  },

  {
    nome: "Noemí Silva Sánchez.",
    cargo: "Doutora, investigadora especialista en turbeiras.",
  },

  { nome: "Pablo de Llano Neira.", cargo: "Xornalista." },

  { nome: "Paula Vázquez Verao.", cargo: "Historiadora e activista cultural." },

  {
    nome: "Pedro de Llano Cabado.",
    cargo:
      "Arquitecto galardoado co Premio de Arquitectura e Rehabilitación de Galicia 2023.",
  },

  {
    nome: "Pepa Rey Castiñeira.",
    cargo: "Doutora da Universidade de Santiago de Compostela e Arqueóloga.",
  },
  {
    nome: "Piero Ricchiardi.",
    cargo:
      "IFRAO Italian Representative. Presidente do CeSMAP- Study Centre and Prehistoric Art Museum Polytechnic of Torino.",
  },

  { nome: "Pilar Sampedro.", cargo: "Docente e crítica literaria." },

  { nome: "Rafael Quintía Pereira.", cargo: "Antropólogo." },

  { nome: "Rodrigo Costoya.", cargo: "Docente e escritor." },

  {
    nome: "Rosa María Regueiro.",
    cargo:
      "Profesora titulada de Economía Aplicada da Universidade de Santiago de Compostela.",
  },

  { nome: "Santiago Bas López.", cargo: "Doutor en Bioloxía e mestre." },

  {
    nome: "Serafín González Prieto.",
    cargo: "Presidente da Sociedade Galega de Historia Natural.",
  },

  { nome: "Simon Doubleday.", cargo: "Profedor en Hofstra University." },

  {
    nome: "Vicente Caramés Moreira.",
    cargo: "Arqueólogo do Museo do Mar de Vigo.",
  },

  { nome: "Vítor Xosé Cabaleiro Barroso.", cargo: "Biólogo." },

  {
    nome: "Xilberte Manso de la Torre.",
    cargo: "Membro da Xunta de Goberno do Instituto de Estudos Miñoráns.",
  },
  {
    nome: "Xosé Couñago Ribeira.",
    cargo: "Profesor",
  },

  { nome: "Xosé Henrique Costas.", cargo: "Profesor da Universidade de Vigo." },

  { nome: "Xosé Lois Vázquez Pérez.", cargo: "Fotógrafo, editor e deseñador." },

  {
    nome: "Xosé Lois Vilar Pedreira.",
    cargo: "Arqueólogo do Instituto de Estudos Miñoráns.",
  },

  { nome: "Xosé Luis Otero.", cargo: "Artista." },

  { nome: "Xosé Luís Santos Cabanas.", cargo: "Escritor." },

  { nome: "Xosé María Lema Suárez.", cargo: "Historiador e escritor." },

  {
    nome: "Xulio Carballo Arceo.",
    cargo: "Doutor da Universidade de Santiago de Compostela e Arqueólogo.",
  },
];

const asinantesOrdenados = asinantes.sort(
  (a, b) => a.nome.toLowerCase() - b.cargo.toLowerCase()
);

export default asinantesOrdenados;
