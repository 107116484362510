import React, { useState, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import guiaFirma from "~/public/guia-firma.png";
import { Button, Flex } from "@chakra-ui/react";

const FullScreenSignatureCanvas = ({ signatureRef }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const containerRef = useRef(null);
  const handleClear = (e) => {
    signatureRef.current.clear();
    e.preventDefault();
  };
  const handleToggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const handleResize = () => {
    const canvas = signatureRef.current;
    const container = containerRef.current;
    const canvasAspectRatio = canvas.width / canvas.height;
    const containerWidth = container.offsetWidth;
    const newCanvasHeight = containerWidth / canvasAspectRatio;
    canvas.setDimensions(containerWidth, newCanvasHeight);
  };

  return (
    <>
      <div
        ref={containerRef}
        className={`signature-container ${isFullScreen ? "full-screen" : ""}`}
        onDoubleClick={handleToggleFullScreen}
      >
        <SignatureCanvas
          penColor="royalblue"
          ref={signatureRef}
          canvasProps={{
            style: {
              width: `calc(95vw)`, // 40px is the padding
              height: "400px", // or set the height to any value you want
              backgroundImage: `url(${guiaFirma})`,
              onClick: () => alert("FOCUS SIGN"),
            },
          }}
          clearOnResize={false}
          // onResize={handleResize}
        />
      </div>
      <Button onClick={handleClear}>🔄 ✍🏻 Repetir Sinatura</Button>
    </>
  );
};

export default FullScreenSignatureCanvas;
