import { Container, Text } from "@chakra-ui/react";

export const Historia = () => {
  return (
    <Container maxW="container.md" p="4" fontFamily="Alegreya, serif">
      <Text as="h1" fontSize="3xl" fontWeight="bold">
        A Contenda do Monte Acibal: Protexendo un Patrimonio Cultural e Natural
      </Text>

      <Text as="h2" fontSize="2xl" fontWeight="bold" mb="4" mt="12">
        O Comezo das Obras
      </Text>

      <Text fontSize="lg" lineHeight="1.75">
        Hai aproximadamente seis meses, na zona de Acibal, situada entre os
        concellos galegos de Moaña, Barro e Campo Lameiro, deron comezo as obras
        de construción dun parque eólico denominado Acibal. Este proxecto foi
        promovido como parte do futuro enerxético de Galicia, pero o que ía
        comezar como unha aposta para o desenvolvemento económico rexional
        transformaríase nunha contenda que chamaría a atención de Galicia e do
        mundo.
      </Text>

      <Text as="h2" fontSize="2xl" fontWeight="bold" mb="4" mt="12">
        Un Tesouro Agochado e a Mobilización Cidadá
      </Text>

      <Text fontSize="lg" lineHeight="1.75">
        O que os traballadores e as autoridades non esperaban era a incrible
        descuberta que se produciría no transcurso das obras. Durante o proceso,
        os veciños e activistas locais fixeron un achado de gran relevancia:
        petróglifos, gravados rupestres e outros elementos arqueolóxicos que
        amosaban a riqueza cultural agochada nas rochas do monte Acibal. Este
        achado amosou a necesidade de protexer non só o patrimonio cultural,
        senón tamén o medio ambiente circundante.
      </Text>
      <Text fontSize="lg" lineHeight="1.75">
        A comunidade local, en colaboración con defensores da natureza e
        expertos en patrimonio, mobilizouse rápidamente. A súa meta era deter o
        avance das obras e garantir a protección deste tesouro agochado e dos
        ecosistemas circundantes. A aparición destes gravados rupestres non só
        subliñou a relevancia histórica e cultural da zona, senón que tamén puxo
        de relevo a necesidade de preservar o patrimonio natural e arqueolóxico
        de Galicia.
      </Text>

      <Text as="h2" fontSize="2xl" fontWeight="bold" mb="4" mt="12">
        Repetidas Peticións de Paralización
      </Text>
      <Text fontSize="lg" lineHeight="1.75">
        A información sobre os petróglifos e a preocupación pola preservación do
        entorno chegou ás autoridades competentes. A súa resposta foi clara: era
        necesario un alto nas obras e unha avaliación completa do impacto do
        proxecto de enerxía eólica sobre a cultura e a natureza do Acibal. A
        petición de paralización das obras foi ben acollida polos defensores do
        patrimonio e os amantes da natureza.
      </Text>

      <Text as="h2" fontSize="2xl" fontWeight="bold" mb="4" mt="12">
        A Decisión do Tribunal: Un Raio de Esperanza
      </Text>
      <Text fontSize="lg" lineHeight="1.75">
        O 24 de outubro de 2023, o Tribunal Superior de Xustiza de Galicia
        (TSXG) emitiu unha decisión que traería esperanza aos defensores do
        patrimonio. O tribunal suspendeu cautelarmente a execución do parque
        eólico Acibal. A razón detrás desta medida foi a preocupación pola
        afectación dos petróglifos atopados, os hábitats naturais e unha zona
        húmida na zona.
      </Text>
      <Text fontSize="lg" lineHeight="1.75">
        O TSXG subliñou a necesidade de actuar con precaución e prevención en
        casos onde o dano podería ser irreparable. A protección do medio
        ambiente e do patrimonio cultural da rexión prevaleceu na súa decisión,
        a pesar dos intereses económicos e enerxéticos involucrados.
      </Text>

      <Text as="h2" fontSize="2xl" fontWeight="bold" mb="4" mt="12">
        O Manifesto Acibal: Unha Voz Conxunta
      </Text>
      <Text fontSize="lg" lineHeight="1.75">
        A coincidencia da suspensión das obras coa presentación do "Manifesto
        Acibal: Un Manifesto Contra o Espolio dos Nosos Montes e Os Seus Bens"
        amosou a unión de voces en defensa do patrimonio cultural e natural de
        Galicia. Este manifesto recibiu o apoio de máis de 80 figuras destacadas
        da cultura e da ciencia galegas. Este acontecemento subliñou a ampla
        base de apoio para a protección do patrimonio galego e amosou a
        diversidade de campos e disciplinas que se uniron para loitar contra o
        espolio dos nosos montes e os seus bens.
      </Text>

      <Text as="h2" fontSize="2xl" fontWeight="bold" mb="4" mt="12">
        A Loxística da Mobilización Cidadá
      </Text>
      <Text fontSize="lg" lineHeight="1.75">
        Un aspecto crucial nesta crónica do Acibal é a loxística da mobilización
        cidadá. Durante meses, a comunidade local e os defensores do patrimonio
        comunicaron de maneira oficial novos petróglifos e outros elementos
        arqueolóxicos, sen que as súas peticións recibisen a debida atención. O
        seu traballo constante, así como a súa determinación por protexer este
        rico legado cultural e natural, fixeron posible que os achados se
        tornasen unha parte esencial da narrativa.
      </Text>
      <Text as="h2" fontSize="2xl" fontWeight="bold" mb="4" mt="12">
        A Loxística O Futuro: Protexendo o Patrimonio Mobilización Cidadá
      </Text>
      <Text fontSize="lg" lineHeight="1.75">
        A crónica do Acibal destaca a importancia de protexer o patrimonio
        cultural e natural de Galicia. As controversias en torno a proxectos de
        enerxía eólica subliñan a necesidade de equilibrar o desenvolvemento
        económico coa conservación dos tesouros culturais e naturais para as
        futuras xeracións. O caso do Acibal amosou que a defensa do patrimonio e
        a protección do medio ambiente non son incompatibles co progreso
        económico, e que a colaboración cidadá pode ter un impacto significativo
        na toma de decisións a nivel rexional e nacional. A historia do Acibal
        continúa evolucionando, pero a súa mensaxe é clara: o noso patrimonio é
        un tesouro que debe ser protexido a toda costa.
      </Text>
    </Container>
  );
};
