import { Outlet } from "react-router-dom";
import React from "react";
import BaseSection from "~/src/components/partials/base-section";

export const Zudreiro = () => {
  return (
    <BaseSection title="Polígono Eólico Zudreiro">
      <Outlet />
    </BaseSection>
  );
};
