import React from "react";
import Hero from "../components/sections/hero";
import amilseneolicos from "../../public/amilseneolicos.png";
import { Stack } from "@chakra-ui/react";
import { TbWindmillOff } from "react-icons/tb";
import ReactMarkdown from "react-markdown";
import BaseSection from "~/src/components/partials/base-section";
import {
  Heading,
  Button,
  Heading,
  Stack,
  Text,
  Link,
  HStack,
} from "@chakra-ui/react";
import MetaForSharing from "~/src/components/meta-for-sharing";

const Sharing = () => {
  const title = "Amil Sen Eólicos";

  const description = "Un movemento veciñal, en defensa do monte Acibal.";

  return (
    <MetaForSharing
      title={title}
      description={description}
      imageURL={amilseneolicos}
    />
  );
};

const ImportantLink = ({ link, text, ...rest }) => (
  <Link href={link} {...rest}>
    <Button
      colorScheme="red"
      rounded="md"
      py="4"
      px="4"
      lineHeight="1"
      size="md"
    >
      <HStack>
        <TbWindmillOff size="1.5em" />
        <Text>{text}</Text>
      </HStack>
    </Button>
  </Link>
);

export const Home = () => (
  <>
    <Stack spacing={16}>
      <Hero
        title="Somos un Movemento Veciñal en Defensa do Acibal"
        subtitle="Queren rodear Amil con **18 eólicos de 200metros**. Nós queremos **informar** á veciñanza do que eso supón **realmente** e preservar as nosas Brañas e Patrimonio."
        video="X3MbrB4yGwk"
        ctaText="Coñece o Manifesto do Acibal ✊🏼"
        ctaLink="/manifesto-acibal"
      />
      <BaseSection title="Asina o Manifesto Acibal">
        <Stack
          spacing={8}
          align={["center", "center", "flex-start", "flex-start"]}
        >
          <Heading
            as="h2"
            size="md"
            color="primary.800"
            opacity="0.8"
            fontWeight="normal"
            lineHeight={1.5}
            textAlign={["center", "center", "left", "left"]}
          >
            <ReactMarkdown>
              Un *MANIFESTO CONTRA O ESPOLIO DOS NOSOS MONTES E OS SEUS BENS*.
            </ReactMarkdown>
            <br />
            <ReactMarkdown>
              Un chamamento a *protexer os montes galegos* e a súa rica herencia
              cultural e natural para as xeracións futuras, promovendo unha
              visión de desenvolvemento sustentable que respecte a historia e a
              natureza da *nosa terra*.
            </ReactMarkdown>
          </Heading>

          <ImportantLink
            fontSize="20"
            link="manifesto-acibal#asinar"
            text="Podes apoialo premendo aquí"
          />
        </Stack>
      </BaseSection>

      <BaseSection title="Queres máis información?">
        <Stack
          spacing={8}
          align={["center", "center", "flex-start", "flex-start"]}
          mb="64"
        >
          <Heading
            as="h2"
            size="md"
            color="primary.800"
            opacity="0.8"
            fontWeight="normal"
            lineHeight={1.5}
            textAlign={["center", "center", "left", "left"]}
          >
            Mantente informado no noso grupo de WhatsApp no que só se recibe
            información (1 mensaxe por día 🙏🏻).
          </Heading>
          <Link href="https://chat.whatsapp.com/KPDXlNLmIOZFphSL9YfXhw">
            <Button fontSize="16" p="4" colorScheme="primary">
              Podes entrar facendo click aquí ☎️
            </Button>
          </Link>
        </Stack>
      </BaseSection>
      <Sharing />
      {/*<Events title="Próximos eventos" /> */}
    </Stack>
  </>
);
