import React from "react";
import { Heading } from "@chakra-ui/react";

const Title = ({ children, ...rest }) => (
  <Heading
    as="h1"
    size="lg"
    fontWeight="bold"
    color="primary.600"
    textAlign={["center", "center", "left", "left"]}
    mb={8}
    {...rest}
  >
    {children}
  </Heading>
);

export default Title;
