import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import Header from "../sections/header";
import Footer from "../sections/footer";

export default function RootLayout(props) {
  return (
    <Flex direction="column" minH="100vh">
      <Header />
      <Box
        w={{ base: "100%", xl: "1200px" }}
        h={{ sm: "1300px" }}
        m="0 auto"
        flex="1"
      >
        {props.children}
      </Box>
      <Footer />
    </Flex>
  );
}
