import { Box, Heading, Text } from "@chakra-ui/react";

export const GdprEn = () => {
  return (
    <Box p={6}>
      <Box bg="gray.100" p={4} borderRadius="md">
        <Heading as="h2" size="md" mb="8">
          Terms and Conditions of the Acibal Manifesto Signature Collection
        </Heading>

        <Text mb="8" fontStyle="italic">
          These Terms of Use constitute an agreement made between you, whether
          personally or on behalf of an entity ('you'), and the Amil Sen Eólicos
          collective regarding the data provided for the signing of the Acibal
          Manifesto on the website http://amilseneolicos.org/manifesto-acibal,
          or in physical signature collections.
          <br />
          <br />
          By accessing the page and signing, you have understood and agreed to
          be bound by these Terms of Use. If you do not agree with all of these
          terms of use, please close this site.
          <br />
          <br />
          If you have already signed and wish to withdraw your signature/data,
          please contact: manifesto@amilseneolicos.org.
        </Text>
        <Text mb="8">
          <strong>1. Purpose</strong>
          <br />
          This manifesto aims to recognize and promote the defense of our
          historical and natural heritage in Galicia, as well as demand the
          protection of this heritage by public entities, above business
          interests.
        </Text>
        <Text mb="8">
          <strong>2. Signature and Adherence</strong>
          <br />
          By signing this manifesto, you are expressing your support and
          solidarity with the values and objectives of the Acibal Manifesto.
          Your signature will contribute to the visibility and impact of this
          initiative in our community and among public authorities and will be
          publicly displayed on the website.
        </Text>
        <Text mb="8">
          <strong>3. Data Privacy</strong>
          <br />
          To sign the manifesto, we will collect and store your personal data,
          which may include your name, last name, email address, and other
          necessary information. This data will be treated with strict
          confidentiality and will not be shared or sold to third parties. It
          will only be used for purposes related to the Acibal Manifesto.
        </Text>
        <Text mb="8">
          <strong>4. Contact</strong>
          <br />
          We will respect your privacy and ensure that your personal information
          is treated securely. If you wish to access, correct, or delete your
          personal information, or if you have any questions about our data
          handling, please contact us via email at{" "}
          <a href="mailto:gdpr@amilseneolicos.org">gdpr@amilseneolicos.org</a>.
        </Text>
      </Box>

      <Box bg="gray.100" p={4} borderRadius="md" mt={4}>
        <Heading as="h2" size="md" mb="8">
          Privacy Policy for the Acibal Manifesto Signature Collection
        </Heading>
        <Text mb="8">
          <strong>1. Data Collection</strong>
          <br />
          When signing the Acibal Manifesto, we will collect personal
          information such as your name, last name, and email address. This
          information is necessary to record your signature and to stay in touch
          with you regarding the manifesto.
        </Text>
        <Text mb="8">
          <strong>2. Data Processing</strong>
          <br />
          We commit to treating your personal information with strict
          confidentiality and not sharing or selling it to third parties. Your
          data will be used exclusively for managing the Acibal Manifesto and
          related communications.
        </Text>
        <Text mb="8">
          <strong>3. Data Security</strong>
          <br />
          We employ security measures to protect your personal information. All
          collected data is stored securely and protected against unauthorized
          access.
        </Text>
        <Text mb="8">
          <strong>4. Access and Rectification</strong>
          <br />
          You can access your personal information, correct errors, or delete
          your data at any time. To do so, please contact us via email at{" "}
          <a href="mailto:gdpr@amilseneolicos.org">gdpr@amilseneolicos.org</a>.
        </Text>
        <Text mb="8">
          <strong>5. Contact</strong>
          <br />
          If you have any questions or concerns regarding our privacy policy or
          the handling of your personal data, please contact us via email at{" "}
          <a href="mailto:gdpr@amilseneolicos.org">gdpr@amilseneolicos.org</a>.
        </Text>
      </Box>
    </Box>
  );
};
