import { Helmet } from "react-helmet";

const MetaForSharing = ({ title, description, url, imageURL }) => {
  const currentURL = window.location.href;

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageURL} />
      <meta property="og:url" content={currentURL} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageURL} />
      <meta name="twitter:site" content="@AmilSenEolicos" />
      <meta name="twitter:creator" content="@AmilSenEolicos" />
    </Helmet>
  );
};

export default MetaForSharing;
