import { useState, useEffect } from "react";
import { SimpleGrid, GridItem, Link, Icon, Text } from "@chakra-ui/react";
import { FaFilePdf, FaFileWord } from "react-icons/fa";

const getFileUrl = (fileId) =>
  `https://drive.google.com/file/d/${fileId}/view?usp=sharing`;

const getColor = (type) => {
  const colors = {
    msword: "#0074D9",
    "vnd.openxmlformats-officedocument.wordprocessingml.document": "#0074D966",
    pdf: "#FF4136",
  };
  return colors[type];
};

const getIconForType = (type) => {
  switch (type) {
    case "pdf":
      return FaFilePdf;
    default:
      return FaFileWord;
  }
};

const FileList = ({ folderId, ...chakraProps }) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const apiKey = "AIzaSyBfLaFznk1liKTdKLEPcpeKOeL1BGUfwHY";
    const url = `https://www.googleapis.com/drive/v3/files?q='${folderId}'+in+parents&orderBy=modifiedTime&key=${apiKey}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const files = data.files
          .filter(
            (file) => file.mimeType !== "application/vnd.google-apps.folder"
          )
          .map((file) => ({
            name: file.name,
            link: getFileUrl(file.id),
            type: file.mimeType.split("/")[1],
          }));
        setFiles(files);
      });
  }, []);

  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing="4" {...chakraProps}>
      {files.map(({ name, link, type }) => {
        const IconComponent = getIconForType(type);
        const color = getColor(type);
        return (
          <GridItem key={name}>
            <Link
              href={link}
              display="flex"
              alignItems="center"
              fontSize="xl"
              _hover={{
                textDecoration: "underline",
                color: color,
              }}
              borderRadius="full"
              borderWidth="2px"
              borderColor={color}
              px={4}
              py={2}
            >
              {IconComponent && (
                <Icon as={IconComponent} mr={2} color={color} />
              )}
              <Text color={color}>{name}</Text>
            </Link>
          </GridItem>
        );
      })}
    </SimpleGrid>
  );
};

export default FileList;
