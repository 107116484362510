import { Box, Text, UnorderedList, ListItem } from "@chakra-ui/react";

const ManifestoEs = () => (
  <Box textAlign="justify" fontFamily="Alegreya, serif">
    <Text fontSize="xl" mb="8">
      Es un hecho que el sector industrial ha perdido mucho peso en Galicia y
      que hay una necesidad apremiante de atraer nuevas inversiones y buscar
      sectores de futuro que puedan aportar riqueza y empleo a nuestra tierra.
      Sin embargo, estas necesidades no deben confundirnos. El desarrollo
      económico debe ser planificado y concebido de acuerdo con una idea global
      de país que recoja las grandes estrategias a seguir y garantice que estos
      proyectos cumplan con criterios de sostenibilidad y respeto por el entorno
      en el que se desarrollan.
    </Text>
    <Text fontSize="xl" mb="8">
      Sin embargo, en los últimos años y en aras de la modernidad, la energía
      verde y el progreso, se ha legislado constantemente en favor de dar
      prioridad a la supuesta utilidad pública y a las iniciativas
      empresariales, olvidando sistemáticamente la protección del patrimonio
      natural, cultural y paisajístico. Esta tendencia es clara en los textos de
      las leyes 5/2017, de 19 de octubre, que declara la utilidad pública de los
      parques eólicos, y en la Ley 9/2021, del 25 de febrero, de simplificación
      administrativa y apoyo a la reactivación económica de Galicia.
    </Text>
    <Text fontSize="xl" mb="8">
      Estamos de acuerdo en que Galicia no puede ni debe renunciar al progreso y
      al desarrollo de su economía. Pero al mismo tiempo, tenemos la obligación
      de preservar y proteger el patrimonio y los recursos naturales.
      Consideramos un error hipotecar el patrimonio de un pueblo, sus recursos
      culturales, naturales y paisajísticos en pos de un enfoque
      económico-empresarial supervisado de manera laxa por las autoridades y
      diseñado principalmente para maximizar los beneficios de grandes grupos
      empresariales y operadores energéticos.
    </Text>
    <Text fontSize="xl" mb="8">
      Las administraciones han sido poco exigentes con la preservación de los
      bienes y recursos de nuestros montes, donde se están construyendo parques
      eólicos. Esto es evidente en el caso del Monte Acibal, donde la comunidad
      local ha descubierto alrededor de 50 hallazgos, como petroglifos y
      elementos etnográficos, en una zona que la empresa eólica considera
      prácticamente "estéril" desde el punto de vista arqueológico. Además, esta
      área cuenta con afloramientos de granito, que son los sustratos más
      comunes donde se han grabado petroglifos, y por su topografía, es más
      probable encontrar inscripciones rupestres, tetrasqueles, cruces
      medievales, robles y otros elementos patrimoniales, ya que dominan una
      gran extensión del territorio.
    </Text>
    <Text fontSize="xl" mb="8">
      El patrimonio cultural y natural, el recurso hídrico, ganadero y forestal
      también son recursos económicos cuando se explotan de manera sostenible.
      Los parques eólicos y las líneas de alta tensión amenazan este patrimonio,
      en particular, el de los montes de Campo Lameiro, la Capital del Arte
      Rupestre con una de las mayores concentraciones de petroglifos de Europa.
      Los petroglifos son elementos arqueológicos que no pueden entenderse sin
      el paisaje que los rodea, y cualquier modificación de este paisaje, además
      de poder alterar bienes aún no descubiertos, cambiará para siempre un
      paisaje arqueológico, comprometiendo la posibilidad de un desarrollo
      sostenible de la gestión cultural y turística de estos recursos, con los
      beneficios económicos que este tipo de turismo rural pueda aportar a la
      región y su gente.
    </Text>
    <Text fontSize="xl" mb="8">
      Lo mismo ocurre con varios hábitats en este monte, que incluyen una amplia
      zona húmeda en la meseta, extensas áreas en pendiente destinadas a la
      producción de madera y áreas considerables que corresponden a las partes
      más elevadas y terrenos abruptos que siempre han sido refugios para lobos
      y otros animales.
    </Text>
    <Text fontSize="xl" mb="8">
      Por lo tanto, no estamos oponiéndonos de manera irresponsable a la
      obtención de energía eólica. Nos oponemos a la forma en que las empresas
      que cuentan con la aprobación de la Xunta de Galicia están llevando a cabo
      estos proyectos.{" "}
    </Text>
    <Text fontSize="xl" mb="8">
      Por todo esto, los abajo firmantes solicitamos:
    </Text>
    <UnorderedList textAlign="left" fontSize="xl" mb="8" ml="8">
      <ListItem mb="4">
        La paralización de las obras en el Acibal debido a los hallazgos y la
        destrucción de posibles bienes como resultado de la falta de un estudio
        detallado. Esto nos lleva a pedir una moratoria en la autorización de
        proyectos eólicos basada en el principio de precaución y no regresión
        ambiental.
      </ListItem>
      <ListItem mb="4">
        Una revisión de los proyectos de macroeólicas en zonas arqueológicas,
        mejorando los instrumentos de evaluación ambiental y replanteando el
        modelo de desarrollo económico de Galicia.
      </ListItem>
      <ListItem mb="4">
        Respetar la soberanía de los municipios que se oponen a la instalación
        de macroeólicas en su territorio y detener la discriminación del entorno
        rural.
      </ListItem>
      <ListItem mb="4">
        Mayor compromiso de las administraciones en la valorización del
        patrimonio que poseen.
      </ListItem>
      <ListItem mb="4">
        La necesidad de conservación de los espacios naturales para garantizar
        su biodiversidad.
      </ListItem>
    </UnorderedList>
    <Text as="h3" fontSize="xl" mb="8" textAlign="center">
      Moraña a 7 de Agosto de 2023
    </Text>
  </Box>
);

export default ManifestoEs;
