import { Box, Heading, Text } from "@chakra-ui/react";

export const Gdpr = () => {
  return (
    <Box p={6}>
      <Box bg="gray.100" p={4} borderRadius="md">
        <Heading as="h2" size="md" mb="8">
          Términos e condicións da recollida de firmas do Manifesto do Acibal
        </Heading>

        <Text mb="8" fontStyle="italic">
          Estes Termos de Uso constitúen un acordo realizado entre ti, xa sexa
          persoalmente ou en nome dunha entidade ('ti') e o colectivo Amil Sen
          Eólicos, referente aos datos aportados para a firma do Manifesto
          Acibal na web http://amilseneolicos.org/manifesto-acibal, ou en
          recollidas de firmas de xeito físico.
          <br />
          <br />
          Ao acceder á páxina e firmar, comprendiches e aceptas ser vinculado
          estes Termos de Uso. Se non estás de acordo con todos estes termos de
          uso, pecha este sitio.
          <br />
          <br />
          Se xa firmaches e queres retirar a túa firma/datos contacta con:
          manifesto@amilseneolicos.org.
        </Text>
        <Text mb="8">
          <strong>1. Obxectivo</strong>
          <br />
          Este manifesto ten como obxectivo recoñecer e promover a defensa do
          noso patrimonio histórico e natural en Galicia, así como demandar a
          protección deste patrimonio por parte das entidades públicas, por
          enriba de intereses empresariais.
        </Text>
        <Text mb="8">
          <strong>2. Firma e Adhesión</strong>
          <br />
          Ao asinar este manifesto, estás a expresar o teu apoio e solidariedade
          cos valores e obxectivos do Manifesto do Acibal. A túa sinatura
          contribuirá a visibilidade e impacto desta iniciativa na nosa
          comunidade e entre as autoridades públicas e amosarase públicamente na
          páxina web.
        </Text>
        <Text mb="8">
          <strong>3. Privacidade de Datos</strong>
          <br />
          Para asinar o manifesto, recolleremos e gardaremos os teus datos
          persoais, que poderán incluír o teu nome, apelidos, dirección de
          correo electrónico e outras informacións necesarias. Estes datos serán
          tratados con estrita confidencialidade e non serán compartidos nin
          vendidos a terceiros. Únicamente se utilizarán con fins relacionados
          co Manifesto do Acibal.
        </Text>
        <Text mb="8">
          <strong>4. Contacto</strong>
          <br />
          Respectaremos a túa privacidade e garantiremos que a túa información
          persoal sexa tratada con seguridade. Se desexas acceder, rectificar ou
          eliminar a túa información persoal, ou se tes algunha dúbida sobre o
          noso tratamento de datos, por favor, contáctanos a través do correo
          electrónico{" "}
          <a href="mailto:gdpr@amilseneolicos.org">gdpr@amilseneolicos.org</a>.
        </Text>
      </Box>

      <Box bg="gray.100" p={4} borderRadius="md" mt={4}>
        <Heading as="h2" size="md" mb="8">
          Política de Privacidade da recollida de firmas do Manifesto do Acibal
        </Heading>
        <Text mb="8">
          <strong>1. Recollida de Datos</strong>
          <br />
          Ao asinar o Manifesto do Acibal, recolleremos información persoal como
          o teu nome, apelidos e dirección de correo electrónico. Esta
          información é necesaria para rexistrar a túa sinatura e para manter
          contacto contigo en relación ao manifesto.
        </Text>
        <Text mb="8">
          <strong>2. Tratamento de Datos</strong>
          <br />
          Comprometémonos a tratar a túa información persoal con estrita
          confidencialidade e a non compartila nin venderla a terceiros. Os teus
          datos serán utilizados exclusivamente para a xestión do Manifesto do
          Acibal e para comunicacións relacionadas.
        </Text>
        <Text mb="8">
          <strong>3. Seguridade de Datos</strong>
          <br />
          Empregamos medidas de seguridade para protexer a túa información
          persoal. Todos os datos recollidos son almacenados de forma segura e
          protexidos contra accesos non autorizados.
        </Text>
        <Text mb="8">
          <strong>4. Acceso e Rectificación</strong>
          <br />
          Poderás acceder á túa información persoal, corrixir erros ou eliminar
          os teus datos en calquera momento. Para facelo, por favor, contáctanos
          a través do correo electrónico{" "}
          <a href="mailto:gdpr@amilseneolicos.org">gdpr@amilseneolicos.org</a>.
        </Text>
        <Text mb="8">
          <strong>5. Contacto</strong>
          <br />
          Se tes algunha dúbida ou preocupación en relación á nosa política de
          privacidade ou ao tratamento dos teus datos persoais, por favor,
          contáctanos a través do correo electrónico{" "}
          <a href="mailto:gdpr@amilseneolicos.org">gdpr@amilseneolicos.org</a>.
        </Text>
      </Box>
    </Box>
  );
};
