import React, { useRef, useState } from "react";
import FullScreenSignatureCanvas from "./full-screen-signature-canvas";
import generatePdf from "./generate-pdf";
import AllegationContent from "./allegation-content";
import Link from "~/src/components/partials/link";
import { Button, Heading, Button, Heading, Box } from "@chakra-ui/react";

import "./allegations.css";

const MAX_ALEGACIONS = 20;

const randomNumber = (max, min = 1) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const getRandom = (list, number) =>
  list.sort(() => 0.5 - Math.random()).slice(0, number);

const initialData = {
  name: "",
  address: "",
  document: "",
  town: "",
  province: "",
  postalCode: "",
};

const Allegations = ({
  polygonShortName,
  polygonName,
  alegaciones,
  introducciones,
  solicitudes,
  minAlegaciones,
}) => {
  const [data, setData] = useState(initialData);
  const [showAllegations, setShowAllegations] = useState(false);
  const [introduction, setIntroduction] = useState(
    getRandom(introducciones, 1)
  );
  const [allegations, setAllegations] = useState(
    getRandom(alegaciones, randomNumber(MAX_ALEGACIONS, minAlegaciones))
  );
  const [request, setRequest] = useState(getRandom(solicitudes, 1));
  const signatureRef = useRef({});
  const { name, address, document, town, province, postalCode } = data;

  const setUserData = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const downloadPdf = () => {
    generatePdf({
      polygonShortName,
      polygonName,
      name,
      address,
      document,
      town,
      province,
      postalCode,
      allegations,
      signature: signatureRef.current,
      introduction,
      request,
    });
  };

  return (
    <Box className="container">
      <Heading
        as="h2"
        size="md"
        color="primary.800"
        opacity="0.8"
        fontWeight="normal"
        lineHeight={1.5}
        textAlign={["center", "center", "left", "left"]}
        mb={10}
      >
        Dende esta web podes encher, firmar e descargar a alegación ao{" "}
        {polygonName}
        <br />
        <br />
        Depois terás que presentala por rexistro: podes seguir{" "}
        <Link href="https://drive.google.com/drive/u/0/folders/1ChB1gJ9rmVNg9fRNzEP1rkJodq7MfqaM">
          este manual
        </Link>
        .
        <br />
        <br />
        Podes correxir calquera dato e volver a xerar o PDF se te trabucaches.
        <br />
      </Heading>
      <div className="form">
        <form>
          <label htmlFor="name">Nome completo</label>
          <input
            type="text"
            id="name"
            value={name}
            name="name"
            onChange={setUserData}
          />

          <label htmlFor="address">Dirección</label>
          <input
            id="address"
            value={address}
            name="address"
            onChange={setUserData}
          />

          <label htmlFor="documentNumber">DNI/NIE</label>
          <input
            type="text"
            id="documentNumber"
            value={document}
            name="document"
            onChange={setUserData}
          />

          <label htmlFor="town">Concello</label>
          <input
            type="text"
            id="town"
            value={town}
            name="town"
            onChange={setUserData}
          />

          <label htmlFor="province">Provincia</label>
          <input
            type="text"
            id="province"
            value={province}
            name="province"
            onChange={setUserData}
          />

          <label htmlFor="postalCode">Código Postal</label>
          <input
            type="text"
            id="postalCode"
            value={postalCode}
            name="postalCode"
            onChange={setUserData}
          />
          <div className="allegations">
            <Button
              className="linkButton"
              onClick={() => setShowAllegations((prevShow) => !prevShow)}
            >
              {!showAllegations
                ? "⬇️ Deplegar para ver as alegacións ⬇️"
                : "⬆️ Deixar de amosar alegacións ⬆️"}
            </Button>
            {showAllegations && (
              <div className="allegationContent">
                <AllegationContent
                  introduction={introduction}
                  allegations={allegations}
                  request={request}
                />
              </div>
            )}
          </div>
          <h2>Firma no recadro</h2>
          <div class="signExplanation">
            <p className="hint">
              Escribe co dedo, ou co rato (pulsa e arrastra) a túa firma.
              <br />
              Ocupa todo o ancho aser posible.
              <br />
              Se queres volver a facela, preme "Repetir". Tes un exemplo á
              dereita.
            </p>
            <img src="https://media.wired.com/photos/5cf07ad08f6eb299a2598e39/2:1/w_899,h_449,c_limit/Culture%20-%20Top%20Art%20-%20Signatures.gif" />
          </div>
          <FullScreenSignatureCanvas signatureRef={signatureRef} />
        </form>
      </div>
      <div className="footer">
        <p className="hint">
          Xa podes descargar a túa alegación firmada e lista para imprimir,
          preme <strong>Xerar PDF</strong>.
        </p>

        <div className="actionButtons">
          <Button
            size="lg"
            mr={8}
            colorScheme="primary"
            className="generate"
            onClick={downloadPdf}
          >
            📃 Xerar PDF
          </Button>
          <Button size="lg" onClick={() => location.reload()}>
            {" "}
            ✍🏻 Comezar de novo
          </Button>
        </div>

        <p className="hint">
          Poderala presentar en calquera rexistro. Podes correxir calquera dato
          e volver a xeralo se te trabucaches.
        </p>
        <p className="hint">
          Se non podes peresentalas en calquera rexistro, podes enviárnolas a:{" "}
          <Link href="mailto:alegacions@amilseneolicos.org">
            alegacions@amilseneolicos.org
          </Link>
          antes do 19 de Abril.
        </p>

        <p className="hint">
          Inda que xa pasara o prazo{" "}
          <strong>pódelas presentar igualmente</strong> só tes que poñer no
          Asunto, antes dos demáis datos, "POR DEFECTOS DE TRAMITACIÓN,
          PRESENTA..." se tes dudas escríbenos ao email.
        </p>
      </div>
    </Box>
  );
};

export default Allegations;
