import React from "react";
import RootLayout from "../components/layouts/root-layout";
import { Outlet } from "react-router-dom";

export const Root = () => {
  return (
    <RootLayout>
      <Outlet />
    </RootLayout>
  );
};
