export const alegaciones = [
  "Ausencia de xustificación da necesidade do P.E. Zudreiro e das súas infraestruturas asociadas de evacuación. As Directrices Enerxéticas 2018‐2020 de Galicia expoñen para a enerxía eólica unha potencia instalada en 2030 de 4.122 MW, cando a finais de 2019 a potencia instalada é de 3.986 MW. En setembro de 2022 esta potencia está amplamente superada, polo que o proxecto só se xustifica desde un punto de vista especulativo. Non se xustifica, polo tanto, a necesidade do P.E. Zudreiro.",
  "Incumprimento da Lei do Sector Eléctrico 24/2013, do Real Decreto 1995/2000 e da Lei de Avaliación Ambiental 21/2013. Green Capital Power, S.L. está a facer unha fragmentación fraudulenta dun único proxecto eólico en dous, xa que, ademais do presente P.E. Zudreiro, de 23 MW, tamén promove o P.E. Anduriña, de 41,4MW. A mercantil promotora recoñece no estudo de impacto ambiental a evacuación conxunta dos dous polígonos eólicos, porén evita a súa avaliación conxunta. Cabe destacar que o P.E. Zudreiro non conta con subestación e que usará a do P.E. Anduriña. Ao respecto da división artificial de proxectos considérese a STSJ GAL 5691/2020 – ECLI:ES:TSJGAL: 2020:5691 de data 09/11/2020 e a sentencia do TS, Sala Terceira, do Contencioso‐Administrativo de 24/04/2006 que sinala o carácter unitario dos parques eólicos. Téñase en conta que recentemente a STS 586/2022 – ECLI:ES:TS:2022/586 do Tribunal Supremo. Sala de lo Contencioso do 09/02/2022 veu a ratificar en firme a sentencia 269/20, do 17 de novembro, Tribunal Superior de Xustiza de Galicia ao respecto da fragmentación de parques eólicos.",
  "A alternativa 0 do proxecto non se analiza de maneira obxectiva. A implantación de parques eólicos nos concellos afectados non supón un desenvolvemento sostible, senón unha industrialización masiva co conseguinte impacto paisaxístico e medioambiental, que destrúe os valores naturais e sociais da contorna, incide directamente nas posibles oportunidades dos municipios afectados e favorece o despoboamento rural. Polo tanto, a pesares do que se afirma no EsIA (Estudo de Impacto Ambiental), o proxecto non atende aos intereses municipais. No presente caso ocorre todo o contrario, xa que se constata un rexeitamento social ao P.E. Zudreiro e aos demais parques eólicos e liñas de evacuación xa existentes ou en tramitación na área xeográfica de afección deste proxecto.",
  "O Convenio Europeo da Paisaxe, de 1 de marzo de 2004 que España ratificou o 26 de novembro de 2007 (BOE de 5/02/2008), defende a necesidade de toda a cidadanía de establecer unha relación sensible co territorio, de beneficiarse desta relación e de participar na determinación das características formais dos lugares que habitan ou frecuentan. O dito Convenio tamén indica que os obxectivos de calidade paisaxística determínaos a Administración, en base ás aspiracións da cidadanía, e non en base ás aspiracións dunha mercantil promotora que actúa no mercado con ánimo de lucro. Polo tanto, estase a obviar a participación pública nun instrumento de ordenación do territorio tan importante e con tanta afección como é este proxecto industrial. Non se pode transformar unilateralmente por mor do interese dunha empresa as paisaxes agrarias, culturais e forestais e o medio de vida das familias en paisaxes industriais e polígonos eólicos que só benefician a empresas como é o presente caso.",
  "Incumprimento grave con Convenio de Aarhus. Aínda que a mercantil promotora presenta o proxecto coma un proxecto cunha función vertebradora do territorio e con interese público e utilidade social, non se presenta avaliación da aceptación social, requisito derivado do artigo 1 do dito Convenio.",
  "Falta de avaliación completa dos impactos acumulados, sinérxicos e globais de todas as infraestruturas, segundo obriga o art.35 da Lei 21/2013, do 9 de decembro de Avaliación Ambiental. As estimacións das afeccións do EsIA do P.E. Zudreiro están totalmente minimizadas, xa que non hai unha avaliación correcta dos efectos sinérxicos, acumulativos e globais da concentración de infraestruturas na parroquia de Amil, que quedaría totalmente rodeada por tres P.E., Acibal, Anduriña e Zudreiro.",
  "No estudo de impacto ambiental do P.E. Zudreiro e dos parques eólicos en tramitación na área de afección destes non se ten en conta a función de descarbonización que realiza o monte galego nin a súa importante función social pois a concepción dos montes como reservorios e depósitos de fixación de carbono e a función social do monte foi obviada. As cimentacións dos parques eólicos, a construción e acondicionamento de vieiros, a instalación de gabias de cableado, a proxección das liñas de evacuación cos respectivos puntos de apoio e accesos aos mesmos, xunto coa remoción necesaria de toneladas de terra, altera de forma irreversible a cuberta vexetal e os recursos hídricos, o que produce a perda de funcionalidade e aptitude agrícola, gandeira e forestal actual de xeito irreversible. Non debería argumentarse a necesidade de instalar parques eólicos en beneficio do medio ambiente prexudicando algo que actualmente está realizando de forma activa un labor protector do solo, da biodiversidade e que mitiga ademais o cambio climático. Téñase en conta a Lei 7/2012, do 28 de xuño, de Montes de Galicia.",
  "As infraestruturas do proxecto eólico Zudreiro coinciden con zonas de máxima sensibilidade ambiental segundo a Clasificación de sensibilidade ambiental proporcionada pola Zonificación ambiental para enerxías renovables elaborada polo Ministerio para la Transición Ecológica y el Reto Demográfico (Resolución de 30 de diciembre de 2020, de la Dirección General de Calidad y Evaluación Ambiental, por la que se formula la declaración ambiental estratégica del Plan Nacional Integrado de Energía y Clima 2021‐2030, «BOE» núm. 9, de 11 de enero de 2021). Segundo a información proporcionada por esta ferramenta, a maior parte das infraestruturas do proxecto eólico Zudreiro sitúanse en áreas non recomendadas para a instalación da enerxía eólica, pola súa máxima sensibilidade ambiental. As infraestruturas do P.E. Zudreiro sitúanse excesivamente próximas a varios núcleos da Amil. O aeroxerador ZU02 está a menos de 1000 metros do Apedrado, o ZU3 a menos de 600 metros de Torre, Outeiro e Pumardetán e o ZU5 está a menos de 700 metros da Torre, Outeiro e Cartamil. Posto que os aeroxeradores miden uns 200 metros, incúmprense as novas disposicións respecto ás distancias a núcleos de poboación, establecidas no art. 39 da Lei 18/2021 do 27 de decembro, de medidas fiscais e administrativas, que indican que deben ser dunha distancia mínima equivalente a 5 veces a altura do aeroxerador (poste mais pa). Considérase un agravio comparativo e para a poboación afectada a non inclusión do P.E. Zudreiro dentro do novo marco legal, cando a Xunta está a recoñecer que a distancia establecida no marco legal anterior é insuficiente para as dimensións dos aeroxeradores actuais.",
  "No EsIA recoñécense impactos negativos sobre a saúde e a calidade de vida da veciñanza das aldeas afectadas (ruído, efecto sombra e campos electromagnéticos) Todas estas afeccións son avaliadas de maneira totalmente subxectiva, pouco precisa e menos rigorosa, obviando que a xente tamén desenvolve a súa vida fora das casas, nas súas eiras, leiras e montes. Ademais, o estudo de impacto ambiental non ten en conta a afección severa á saúde psicolóxica da veciñanza, que se amosa seriamente preocupada diante dos danos e perdas que o P.E. Zudreiro lles vai producir. As medicións feitas dalgunhas das repercusións sobre a veciñanza son tan pouco rigorosas que na maioría dos casos deixan todo á espera do que aconteza unha vez que o polígono eólico se poña en funcionamento, o que provoca moita inquedanza e angustia na veciñanza. Véxase EsIA, páx.98 “ No obstante, será necesario realizar un seguimiento durante los primeros años de funcionamiento del parque y en caso de superarse el nivel máximo permitido tendrán que llevarse a cabo las medidas correctoras pertinentes”.",
  "Prexuízos significativos para os chans e o medio ambiente. O chan é un recurso natural non renovable e finito cuxo proceso de formación leva centos de anos e é parte fundamental no equilibrio dos ecosistemas. Coa implantación das cimentacións dos parques, as escavacións e remocións de toneladas de terras durante a súa instalación, elimínase a materia orgánica dos chans e pérdese a produtividade destes. Non só se produce un cambio de usos (agrícolas de cultivo, de pasteiros ou forestais), tamén se produce unha transformación urbanística non amparada legalmente, ao quedar os chans erosionados e perder de forma irreversible a súa produtividade orixinaria.",
  "Os cinco aeroxeradores deste polígono eólico, polo feito de estar en zona de seguridade aérea e superar os 150 metros de altura total, teñen que levar luces na parte superior da góndola e tamén teñen que ter instaladas na torre luces a distintos niveis, e vanse manter iluminados en todo momento. O efecto da iluminación do P.E. Zudreiro, xunto coa de parques eólicos próximos supón unha severa contaminación luminosa, moi ó contrario do que se di no EsIA.",
  "Afección severa para as aves e insectos como consecuencia da contaminación luminosa xerada polas infraestruturas eólicas. Afección severa á necesaria conectividade ecolóxica dos ecosistemas. No P.E. Zudreiro, ao igual que no proxecto eólico Anduriña e no Acibal, xa autorizado, non se tivo en conta a contaminación luminosa nin a incidencia desta sobre aves, insectos e os espazos naturais e as zonas húmidas. Tampouco se tivo en conta a contaminación luminosa xerada polos aeroxeradores doutros parques xa implantados e en funcionamento na mesma área xeográfica.",
  "A iluminación dos aeroxeradores presenta moitas outras problemáticas sociais, xera molestias a distintos niveis á poboación circundante, como en canto á saúde pública, e confírelle á paisaxe «unha imaxe de desasosego». Organizacións de referencia como SEO/Birdlife alertaron que as luces e escintileos luminosos provenientes dos aeroxeradores alteran os ciclos naturais de moitos seres vivos durante o período nocturno e as súas pautas de comportamento ao modificar os seus ritmos circadianos, o que chega a desorientar ás aves e certos anfibios nos seus procesos migratorios.",
  "Inadecuada localización elixida para o desenvolvemento do P.E. Zudreiro por situarse sobre zonas de captación sensibles e sobre masas de augas soterradas. O proxecto presenta graves afeccións á rede hídrica e aos recursos hidrolóxicos xa que afecta de xeito grave a captacións de auga que non se recollen no estudo de impacto ambiental. Por exemplo, moitos dos mananciais que dan auga á veciñanza da Amil e Cosoirado, nacen nesta área. Todo isto, nin se reflicte nin se avalía xa que non hai un estudo hidroxeolóxico que garanta a non afección á zona de acuíferos existentes na área. Téñase en conta a Directiva Marco da Auga.",
  "O impacto paisaxístico e a descontextualización do patrimonio cultural é inasumible. O elevado número de infraestruturas eólicas e liñas eléctricas existentes na área de afección do P.E. Zudreiro, tendo en conta a existencia da tramitación do P.E. Anduriña e doutro proxecto industrial xa autorizado como o P.E. Acibal e a súa LAT de evacuación, desvirtúa a funcionalidade das áreas e lugares de especial interese paisaxístico, itinerarios e miradoiros e afecta de forma irreversible a conectividade ecolóxica. O impacto visual e paisaxístico do P.E. Zudreiro prevese severo, crítico e persistente durante toda a súa vida útil. O mesmo ocorrerá co proxecto eólico Anduriña e co xa autorizado Acibal.",
  "Afección paisaxística e visual severa. Prexuízos irreversibles para as áreas e lugares de especial interese paisaxístico, para a rede de miradoiros e para os núcleos rurais. Descontextualización do patrimonio cultural e xeración de feísmo paisaxístico. Os municipios nos que se sitúa o P.E. Zudreiro, caso de Moraña e Campo Lameiro, albergan numerosos elementos de interese turístico, tales como redes de sendeirismo, miradoiros, áreas recreativas, lugares sacros, etc. Un exemplo disto é a Área de Especial Interese Paisaxístico de Castro de Penalba (AEIP_08_09), a escasos 200 metros de distancia dun dos aeroxeradores. Ademais, o P.E. estaría situado nun radio de menos duns 10 km das seguintes áreas: AEIP Área Arqueolóxica de Campo Lameiro (2,2 km), AEIP San Xusto‐Río Lérez (3,6 km), AEIP Río Barosa (4,9 km) y AEIP Val do Alto Almofrei (10 km). Na zona vanse ver afectados varios miradoiros: o miradoiro de Penalba, o miradoiro de Morañó e o miradoiro do Monte das Fragas, dende onde se verán en todos os casos os 5 aeroxeradores; ademais do miradoiro do Monte Acibal, dos miradoiros do Monte Castelo e Monte Castelo 2 e do miradoiro sobre o río Almofrei.",
  "Afección severa ao Patrimonio Cultural e arqueolóxico. Impacto visual e paisaxístico severo e persistente durante toda a vida útil do P.E. Zudreiro. Non se avalía correctamente a afección ó Patrimonio Cultural, polo que algúns elementos do mesmo vense gravemente prexudicados. No Estudo de Impacto Cultural non se contempla a totalidade do patrimonio arqueolóxico potencialmente afectado, o patrimonio etnográfico e inmaterial non é tido en conta. A afección visual e paisaxística é severa en canto ao rico patrimonio cultural e arqueolóxico da zona, entre eles numerosos petróglifos, mámoas e gravados. Cabe destacar tamén a xeración de feísmo paisaxístico no conxunto sacro da Igrexa Parroquial de San Mamede de Amil e conxunto da casa reitoral (a uns 500 metros dun dos aeroxeradores) e Santuario dos Milagres de Amil.",
];

export const introducciones = [
  "Á vista da RESOLUCIÓN do 6 de febreiro de 2023, da Xefatura Territorial de Pontevedra, pola que se somete á información pública o estudo de impacto ambiental e as solicitudes de autorización administrativa previa e de construción e a aprobación do proxecto sectorial de incidencia supramunicipal (proxecto de interese autonómico) do proxecto do parque eólico Zudreiro, emprazado nos concellos de Moraña e Campo Lameiro da provincia de Pontevedra (expediente IN408A 2019/45) DOG Núm. 45, de 6 de marzo de 2023.",
  "Á vista da RESOLUCIÓN do 6 de febreiro de 2023, da Xefatura Territorial de Pontevedra, pola que se somete á información pública o estudo de impacto ambiental e as solicitudes de autorización administrativa previa e de construción e a aprobación do proxecto sectorial de incidencia supramunicipal (proxecto de interese autonómico) do proxecto do parque eólico Zudreiro, emprazado nos concellos de Moraña e Campo Lameiro da provincia de Pontevedra (expediente IN408A 2019/45) DOG Núm. 45, de 6 de marzo de 2023, o abaixo firmante.",
  "En vista da RESOLUCIÓN do 6 de febreiro de 2023, da Xefatura Territorial de Pontevedra, pola que se somete á información pública o estudo de impacto ambiental e as solicitudes de autorización administrativa previa e de construción e a aprobación do proxecto sectorial de incidencia supramunicipal (proxecto de interese autonómico) do proxecto do parque eólico Zudreiro, emprazado nos concellos de Moraña e Campo Lameiro da provincia de Pontevedra (expediente IN408A 2019/45) DOG Núm. 45, de 6 de marzo de 2023.",
  "Tendo en conta a RESOLUCIÓN do 6 de febreiro de 2023, da Xefatura Territorial de Pontevedra, pola que se somete á información pública o estudo de impacto ambiental e as solicitudes de autorización administrativa previa e de construción e a aprobación do proxecto sectorial de incidencia supramunicipal (proxecto de interese autonómico) do proxecto do parque eólico Zudreiro, emprazado nos concellos de Moraña e Campo Lameiro da provincia de Pontevedra (expediente IN408A 2019/45) DOG Núm. 45, de 6 de marzo de 2023, mediante este documento :",
];

export const solicitudes = [
  "O rexeitamento do estudo de impacto ambiental e das solicitudes de autorización administrativa previa e de construción e da aprobación do proxecto sectorial de incidencia supramunicipal, en concreto, das instalacións do parque eólico Zudreiro, situado nos concellos de Moraña e Campo Lameiro, da provincia de Pontevedra (expediente IN408A 2019/45), polo que se pide a súa retirada definitiva por: infracción normativa, invalidez do documento ambiental e a súa tramitación (sesgo de información pública á cidadanía e outras AAPP), así como pola súa incompatibilidade cos valores ambientais, patrimoniais, paisaxísticos e turísticos presentes na área de afeción do proxecto e a falla de licencia social.",
  "Esíxese a retirada definitiva do proxecto do parque eólico Zudreiro, situado nos municipios de Moraña e Campo Lameiro, provincia de Pontevedra (expediente IN408A 2019/45), debido á infracción normativa, invalidez do documento ambiental e a súa tramitación sesgada, así como pola súa incompatibilidade cos valores ambientais, patrimoniais, paisaxísticos e turísticos presentes na área de influencia do proxecto e a falta de licenza social. Isto débese ao rexeitamento do estudo de impacto ambiental, e das solicitudes de autorización administrativa previa e de construción e da aprobación do proxecto sectorial do parque eólico.",
  "Solicítase a retirada definitiva do proxecto eólico Zudreiro, situado nos concellos de Moraña e Campo Lameiro, da provincia de Pontevedra (expediente IN408A 2019/45), debido ao rexeitamento do estudo de impacto ambiental e das solicitudes de autorización administrativa previa e de construción e da aprobación do proxecto. Estas alegacións baséanse na infracción normativa, a invalidez do documento ambiental e a súa tramitación, a incompatibilidade cos valores ambientais, patrimoniais, paisaxísticos e turísticos presentes na área afectada e a falta de licenza social.",
  "O rexeitamento do estudo de impacto ambiental e das solicitudes de autorización administrativa previa e de construción e da aprobación do proxecto do parque eólico Zudreiro. Solicitude baseada en diferentes motivos. Un deles é a infracción normativa, xa que se considera que o proxecto non cumpre coa normativa ambiental e urbanística vixente na zona de afectación. Ademais, arguméntase que o documento ambiental presentado para a tramitación do proxecto non cumpre cos requisitos esixidos pola normativa, o que o invalida. Outro motivo de rexeitamento é a incompatibilidade do proxecto cos valores ambientais, patrimoniais, paisaxísticos e turísticos presentes na área de afectación. Considérase que a instalación do parque eólico Zudreiro tería un impacto visual e paisaxístico negativo na zona, afectando de maneira significativa ao patrimonio cultural e natural. Así mesmo, alégase a falta de licenza social, é dicir, a falta de apoio da cidadanía e das comunidades afectadas polo proxecto, á vista das alegacións presentadas.",
  "Solicito o rexeitamento do estudo de impacto ambiental e das solicitudes de autorización administrativa previa e de construción e da aprobación do proxecto do parque eólico Zudreiro en Pontevedra (expediente IN408A 2019/45), por infración normativa, invalidez do documento ambiental e a súa tramitación, incompatibilidade cos valores ambientais, patrimoniais, paisaxísticos e turísticos presentes na área de afección do proxecto e a falta de licenza social.",
  "Solicítase o rexeitamento do estudo de impacto ambiental e das solicitudes de autorización administrativa previa e de construción e da aprobación do proxecto do proxcto do parque eólico Zudreiro debido á súa infracción normativa, invalidez do documento ambiental, incompatibilidade cos valores ambientais, patrimoniais, paisaxísticos e turísticos presentes na área de afeción do proxecto, e falta de licenza social. É necesario que se dea unha resposta razoada a estas alegacións, de acordo con a Lei 39/2015, do 1 de outubro, do Procedemento Administrativo Común das Administracións Públicas. A falta de resposta podería dar lugar á nulidade da tramitación.",
];
