import React from "react";
import { Icon } from "@chakra-ui/react";
import { TbWindmillOff } from "react-icons/tb";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link, Box, Text, HStack } from "@chakra-ui/react";
import "./logo.css";

const WindmillIcon = () => (
  <Icon className="logo-eolico" boxSize={8} color="#999" as={TbWindmillOff} />
);

const Logo = (props) => {
  return (
    <Box {...props}>
      <Link as={ReactRouterLink} to="/">
        <HStack>
          <WindmillIcon />
          <Text fontSize="xl" fontWeight="bold">
            Amil <span style={{ color: "indianred" }}>SEN</span> Eólicos
          </Text>
        </HStack>
      </Link>
    </Box>
  );
};
export default Logo;
