import { useState, useEffect } from "react";
import { Box, Text, Button, HStack, Spinner } from "@chakra-ui/react";
import Link from "~/src/components/partials/link";
import { FaFilePdf } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";

const fetchResults = async (page) => {
  const url = `/api/buscarAnunciosPublico.do?method=listado&texto=parque+e%C3%B3lico&soloTitulo=true&fraseExacta=true&provincia=&localidad=&numDogDesde=&numDogHasta=&numPaginaDesde=&numPaginaHasta=&fechaDisposicion=&fechaPubDesde=&fechaPubHasta=&listado_taxorga=&listado_tematica=&listado_colectivo=&listado_rangos=&listado_seccion=&fechaPublicacionRefinado=&etiquetas=&criterioOrdenacion=ORDENACION_FECHA&pagina=${page}`;
  const response = await fetch(url);
  const data = await response.json();
  return data.response.listas.datos_informe;
};

export const DogEolicos = () => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const data = await fetchResults(page);
      setResults(data);
      setIsLoading(false);
    }
    fetchData();
  }, [page]);

  const handlePrevPage = () => {
    setPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <Box>
      {results.map((result) => (
        <Box
          opacity={isLoading ? 0.25 : 1}
          key={result.id}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="space-between"
          mb={8}
        >
          <Box flex={1} mr={4}>
            <Text fontSize="sm" color="gray.600" fontWeight="bold">
              {result.fechaDogFormateada}
            </Text>
          </Box>
          <Box flex={3} mr={4}>
            <Link
              href={`https://www.xunta.gal${result.rutaHtml}`}
              mb={2}
              pr={8}
              isExternal
              textDecoration="none"
            >
              <Text fontSize="xl" fontWeight="bold" color="primary.600">
                {result.sumario}
              </Text>
            </Link>
          </Box>
          <Box flex={2} display="flex" flexDirection="row">
            <Link
              href={`https://www.xunta.gal${result.rutaHtml}`}
              mb={2}
              pr={8}
              isExternal
            >
              <HStack>
                <CgWebsite size="2em" /> <Text>Ver completo</Text>
              </HStack>
            </Link>
            <Link
              href={`https://www.xunta.gal${result.rutaPdf}`}
              isExternal
              pr={8}
            >
              <HStack>
                <FaFilePdf size="2em" /> <Text>Ver en PDF</Text>
              </HStack>
            </Link>
          </Box>
        </Box>
      ))}
      <Box mt={4} display="flex" justifyContent="space-between" py={8}>
        <Button
          disabled={isLoading}
          onClick={handlePrevPage}
          isDisabled={page === 1}
        >
          Páxina anterior ({page - 1 || ""})
        </Button>
        <Button
          disabled={isLoading}
          onClick={handleNextPage}
          isDisabled={results.length === 0}
        >
          Seguinte páxina ({page})
        </Button>
      </Box>
    </Box>
  );
};
