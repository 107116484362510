import cartel from "../../public/2023.jpg";
import { Image, Box, Flex } from "@chakra-ui/react";

export const PorquinhoSolidario = () => {
  return (
    <Box width="100%">
      <Image src={cartel} alt="Porquiño solidario 2023" objectFit="cover" />
    </Box>
  );
};
