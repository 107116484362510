import pdfMake from "pdfmake/build/pdfmake";

function toFilenameSafeString(inputString) {
  // Replace special characters with hyphens
  const filenameSafeString = inputString
    .replace(/[^\w\s-]/g, "") // Remove non-word characters except hyphen and whitespace
    .replace(/\s+/g, "-") // Replace whitespace with hyphens
    .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens

  return filenameSafeString;
}

pdfMake.fonts = {
  // download default Roboto font from cdnjs.com
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
};

const signatureTexts = [
  "Firma, o ",
  "Firma o día",
  "Firmado o: ",
  "Firmado o día :",
  "Así o asino, a ",
  "Así o asino",
  "Asinado o :",
  "Data da firma: ",
];

const requestIntroTexts = [
  "Por todo o Anterior, SOLICITA:",
  "Exposto o anterior, solicito:",
  "Tento todo o anterior en conta, solicita:",
  "Por todo o anteriormente escrito, SOLICITA:",
  "Dado todo o escrito anteriormente, SOLICITO",
  "SOLICITA, á vista de todo o descrito:",
  "Quero solicitar, á vista de todo o listado:",
  "Conclúo, apoiándome en todo o descrito: ",
  "Quero solicitar, visto o descrito neste documento:",
];

const introTexts = [
  "Polo medio do seguinte escrito, presenta as seguintes alegacións:",
  "ALEGA:",
  "Alega, polo medio deste escrito:",
  "Presenta as seguintes ALEGACIÓNS:",
  "Alega mediante este escrito:",
  "Remite este escrito, presentando as seguintes ALEGACIÓNS",
  "Mediante este escrito, ALEGA:",
  "Mediante este escrito, alega:",
];

const getRandom = (list, number) =>
  list.sort(() => 0.5 - Math.random()).slice(0, number);

const randomList = (allegations) =>
  Math.random > 0.5 ? { ol: allegations } : { ul: allegations };

const randomSignatureText = (currentDate) => {
  const signatureText = getRandom(signatureTexts, 1);
  return {
    text: `${signatureText} ${currentDate}`,
    style: "data",
    alignment: Math.random() > 0.5 ? "center" : "left",
    margin: [0, 40, 20, 0],
  };
};

const randomTitleStyle = () => (Math.random() > 0.5 ? "subtitle" : "subHeader");

const generatePdf = ({
  name,
  address,
  document,
  town,
  province,
  postalCode,
  allegations,
  signature,
  request,
  introduction,
  polygonShortName = "Zudreiro",
  polygonName = "POLÍGONO EÓLICO ZUDREIRO (expediente IN408A 2019/46)",
  destination = "CONSELLERÍA DE ECONOMÍA, INDUSTRIA E INNOVACIÓN",
  destinationSection = "XEFATURA TERRITORIAL DE PONTEVEDRA",
  destinationAddress = "Avda. María Victoria Moreno, 43-5º. 36003 Pontevedra",
}) => {
  const currentDate = new Date().toLocaleDateString("es-ES");
  const allegationIntroText = {
    text: getRandom(introTexts, 1),
    style: randomTitleStyle(),
  };
  const allegationsList = randomList(allegations);
  const signatureText = randomSignatureText(currentDate);
  const requestIntroText = {
    text: getRandom(requestIntroTexts, 1),
    style: randomTitleStyle(),
  };

  const signatureDataUrl = signature.toDataURL();
  if (!name || !address || !document || !town || !province || !postalCode) {
    alert("Por favor, enche todos os datos.");
    return;
  }

  if (signature.isEmpty()) {
    alert("Por favor, asina a alegación.");
    return;
  }

  if (allegations.length === 0) {
    alert("NON hai alegacións dispoñibles.");
    return;
  }

  const docDefinition = {
    content: [
      {
        text: `Alegacións á ${polygonName}`,
        style: "header",
      },
      { text: `Nome: ${name}`, style: "data" },
      { text: `DNI/NIE: ${document}`, style: "data" },
      {
        text: `Enderezo: ${address}. ${town}, ${postalCode} (${province})`,
        style: "data",
      },
      { text: introduction, margin: [0, 10, 0, 10] },
      allegationIntroText,
      allegationsList,
      requestIntroText,
      { text: request },
      signatureText,
      {
        image: signatureDataUrl,
        fit: [231, 77],
        alignment: "center",
      },
      {
        columns: [
          { text: "" },
          {
            stack: [
              { text: "Dirixido a:", bold: true },
              {
                text: destination,
                bold: true,
                margin: [0, 0, 0, 5],
              },
              {
                text: destinationSection,
                margin: [0, 0, 0, 5],
              },
              {
                text: destinationAddress,
                margin: [0, 0, 0, 5],
              },
            ],
            alignment: "left",
            margin: [0, 20, 20, 0],
          },
        ].sort(() => 0.5 - Math.random()),
        margin: [0, 50, 0, 0],
      },
    ],

    footer: function (currentPage, pageCount) {
      return {
        text: `${currentPage.toString()} de ${pageCount.toString()}`,
        alignment: "right",
        margin: [0, 0, 40, 0],
      };
    },

    styles: {
      defaultStyle: {
        font: "Roboto",
      },
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subHeader: {
        fontSize: 14,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      data: {
        fontSize: 13,
        bold: true,
        margin: [0, 5, 0, 0],
      },
      subtitle: {
        fontSize: 14,
        bold: true,
        margin: [0, 10, 0, 10],
        decoration: "underline",
      },
    },
  };

  pdfMake
    .createPdf(docDefinition)
    .download(
      toFilenameSafeString(
        `Alegacions-${polygonShortName}-${document}-${currentDate}.pdf`
      )
    );
};

export default generatePdf;
