import BaseSection from "~/src/components/partials/base-section";
import { Box, Flex, SimpleGrid, Image, Button } from "@chakra-ui/react";
import BaseSection from "~/src/components/partials/base-section";
import FileList from "~/src/components/partials/file-list";
import Description from "~/src/components/partials/description";
import Link from "~/src/components/partials/link";
import tourinhantivo from "../../../public/tourinhantivo.png";
import TipBox from "~/src/components/tip-box";

function LatInfo() {
  return (
    <SimpleGrid columns={{ base: 1, md: 2 }}>
      <Box p="4">
        <Image
          src={tourinhantivo}
          alt="Porquiño solidario 2023"
          objectFit="cover"
        />
      </Box>
      <Box p="8">
        <iframe
          src="https://www.google.com/maps/d/embed?mid=13-BNv7QSbuN8BQTx0rmebemtuQ3UgOE&ehbc=2E312F"
          width="100%"
          height="480"
        ></iframe>
      </Box>
    </SimpleGrid>
  );
}

const AllegationInfo = () => (
  <BaseSection
    title="Alegacións a esta Liña"
    explanation={
      <>
        Aquí atoparás todos os modelos de alegación dos que dispoñemos para a{" "}
        <strong>
          modificación da Utilidade Pública da LAT Pico Touriñán Tivo
        </strong>
        .
        <br />
        Pódelas presentar en calquera rexistro, no teu concello, na Xunta, na
        subdelegación do Goberno...
        <br />
        Se tes certificado dixital, tamén as podes presentar online, se non
        sabes como sigue{" "}
        <Link href="https://drive.google.com/drive/u/0/folders/1ChB1gJ9rmVNg9fRNzEP1rkJodq7MfqaM">
          esta guía
        </Link>
        , é moi doado.
      </>
    }
  >
    <FileList folderId="1ucyjoss6V6q2uLugfr0SyzxTcaULSQ81" mb="10" />

    <Description>
      Podes presentar calquera delas, só hai varios modelos para que non sexan
      todas iguais
      <br />
      O ideal é que descargues use un dos .doc e engadas o que che apeteza
      alegar.
      <br />
      Tamén podes usar como base unhas moi completas coma as de{" "}
      <Link href="https://ecoloxistasenaccion.fala.gal/alegacions-modificacion-utilidade-publica-lat-pico-tourinan-tivo/">
        E.A. Galicia Atlántica e Verde
      </Link>{" "}
      e as aumentes co que tí consideres.
    </Description>
  </BaseSection>
);

const Online = () => (
  <BaseSection title="Qué facer se non podo imprimilas?" mt={16}>
    <Flex flexDirection="column" pb={20} w="100%">
      <Description>
        Se non podes imprimir as alegacións ou estás fora de España, pódelas
        firmar online dende esta páxina web, descargarase un PDF firmado que
        podes enviarnos a:{" "}
        <Link href="mailto:alegacions@amilseneolicos.org">
          alegacions@amilseneolicos.org
        </Link>
      </Description>

      <Link href="lat-tourinhan-tivo/alega">
        <Button w="100%" size="lg" colorScheme="orange">
          ALEGA AQUÍ!
        </Button>
      </Link>
    </Flex>
  </BaseSection>
);

const DueDate = () => (
  <BaseSection title="Qué facer se pasou o Prazo?" mt={16}>
    <Flex flexDirection="column" w="100%">
      <Description>
        Podes entregalas todavía, só tes que poñer no asunto:{" "}
        <strong>"POR DEFECTOS NA TRAMITACIÓN ..."</strong>e seguir co asunto
        orixinal
      </Description>
    </Flex>
  </BaseSection>
);

export const HomeLatTourinhanTivo = () => (
  <BaseSection title="Liña de Alta Tensión Pico Touriñán - Tivo">
    <Flex flexDirection="column">
      <LatInfo />
      <AllegationInfo />
      <DueDate />
      <Online />
      <TipBox tipTitle="Sabías que...">
        Se aínda non asinaches o <b>Manifesto Acibal</b>, podes leelo{" "}
        <Link
          color="blue.500"
          fontWeight="bold"
          textDecoration="underline"
          href="manifesto-acibal"
        >
          aquí
        </Link>
        .
      </TipBox>
    </Flex>
  </BaseSection>
);
