import React from "react";
import {
  Container,
  SimpleGrid,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";

import { TbWindmillOff } from "react-icons/tb";

const Feature = ({ text, icon, iconBg, to }) => {
  return (
    <Stack direction="row" align="center">
      <Flex
        w={8}
        h={8}
        align="center"
        justify="center"
        rounded="full"
        bg={iconBg}
      >
        {icon}
      </Flex>
      {to ? (
        <Link href={to}>{text}</Link>
      ) : (
        <Text fontWeight={600}>{text}</Text>
      )}
    </Stack>
  );
};

export function Documentation() {
  return (
    <Container maxW="6xl" py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Heading as="h2" size="lg">
            Toda a información da que dispoñemos dos Polígonos que queren rodear
            Amil
          </Heading>
          <Text color="gray.500" fontSize="lg">
            Nun formato máis claro e fácil de consultar que as canles
            "oficiais".
          </Text>
          <Text color="gray.500" fontSize="lg">
            Pulsa nas carpetas e accederás á información de:
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.100", "gray.700")}
              />
            }
          >
            <Feature
              to="https://drive.google.com/drive/u/0/folders/18D-hgf652kjkEsiKKcfn3_v2Qr2OcqZ-"
              icon={<Icon as={TbWindmillOff} color="yellow.500" w={5} h={5} />}
              iconBg={useColorModeValue("orange.100", "orange.900")}
              text="Polígono Acibal"
            />
            <Feature
              to="https://drive.google.com/drive/u/0/folders/1Le96DPrpJQ4qZ6vBKHJs-fPqo7n-YBf7"
              icon={<Icon as={TbWindmillOff} color="yellow.500" w={5} h={5} />}
              iconBg={useColorModeValue("yellow.100", "yellow.900")}
              text="Polígono Anduriña"
            />
            <Feature
              to="https://drive.google.com/drive/u/0/folders/1SM4Aj-Gj9D-wZ0VCyjVOZBs-S6V19qki"
              icon={<Icon as={TbWindmillOff} color="purple.500" w={5} h={5} />}
              iconBg={useColorModeValue("purple.100", "purple.900")}
              text="Polígono Zudreiro"
            />
          </Stack>
        </Stack>
        <Flex>
          <iframe
            src="https://drive.google.com/embeddedfolderview?id=1ChB1gJ9rmVNg9fRNzEP1rkJodq7MfqaM"
            style={{ width: "100%", height: "50vw", border: 0 }}
          ></iframe>
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
