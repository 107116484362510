import React from "react";
import { Icon } from "@chakra-ui/react";
import { MdMap, MdNotificationImportant } from "react-icons/md";
import { BsFiletypePdf } from "react-icons/bs";
import { FaFileSignature, FaPiggyBank } from "react-icons/fa";
import { IoDocumentsOutline } from "react-icons/io5";
import { RiHome3Fill } from "react-icons/ri";
import { RxVideo } from "react-icons/rx";
import { GiEgyptianWalk } from "react-icons/gi";
import { TbSpiral } from "react-icons/tb";

const MapIcon = () => <Icon as={MdMap} />;
const PdfIcon = () => <Icon as={BsFiletypePdf} />;
const SignatureIcon = () => <Icon as={FaFileSignature} />;
const DocumentationIcon = () => <Icon as={IoDocumentsOutline} />;
const HomeIcon = () => <Icon as={RiHome3Fill} />;
const VideoIcon = () => <Icon as={RxVideo} />;
const ImportantIcon = () => <Icon as={MdNotificationImportant} />;
const PetroglyphIcon = () => <Icon as={GiEgyptianWalk} />;
const SpiralIcon = () => <Icon as={TbSpiral} />;

export default [
  {
    name: "Comezo",
    to: "/",
    icon: <HomeIcon />,
  },
  {
    name: "Mapas",
    to: "/mapa",
    about: "Mapas dos polígonos eólicos e liñas en Amil",
    icon: <MapIcon />,
  },
  {
    name: "Petroglifos en PERIGO",
    to: "/petroglifos",
    about: "Mapa dos petroglidos do Acibal",
    icon: <PetroglyphIcon />,
  },
  {
    name: "Vídeos",
    to: "/videos",
    about: "Vídeos sobre os polígonos eólicos na amil",
    icon: <VideoIcon />,
  },
  {
    name: "Manifesto Acibal",
    to: "/manifesto-acibal",
    about: "Manifesto Acibal",
    icon: <SpiralIcon />,
  },
];
