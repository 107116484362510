import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link, Box, Flex, Text, Button, HStack } from "@chakra-ui/react";
import sections from "../partials/sections";
import Logo from "../partials/logo";

const MenuItem = ({ children, isLast, to = "/", alt, onClick, ...rest }) => {
  return (
    <Text
      mb={{ base: isLast ? 0 : 8, md: 0 }}
      mr={{ base: 0, md: isLast ? 0 : 8 }}
      display="block"
      {...rest}
    >
      <Link
        as={ReactRouterLink}
        to={to}
        textDecoration={"none"}
        onClick={onClick}
        aria-label={alt}
      >
        {children}
      </Link>
    </Text>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="white"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const Header = (props) => {
  const [show, setShow] = React.useState(false);
  const toggleMenu = () => setShow(!show);

  const handleMenuItemClick = () => {
    setShowMenu(false);
  };

  return (
    <Flex justify={"center"}>
      <Flex
        w={{ base: "100%", xl: 1200 }}
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        mb={4}
        py={8}
        px={{ base: 8, md: 16, xl: 0 }}
        bg={["primary.900", "primary.900", "transparent", "transparent"]}
        color={["white", "white", "primary.700", "primary.700"]}
        {...props}
      >
        <Flex align="center">
          <Logo
            w="200px"
            color={["white", "white", "primary.500", "primary.500"]}
          />
        </Flex>

        <Box display={{ base: "block", md: "none" }} onClick={toggleMenu}>
          {show ? <CloseIcon /> : <MenuIcon />}
        </Box>

        <Box
          display={{ base: show ? "block" : "none", md: "block" }}
          flexBasis={{ base: "100%", md: "auto" }}
        >
          <Flex
            align="center"
            justify={["center", "space-between", "flex-end", "flex-end"]}
            direction={["column", "column", "row", "row"]}
            pt={[4, 4, 0, 0]}
          >
            {sections.map((section, index) =>
              index < sections.length - 1 ? (
                <MenuItem
                  to={section.to}
                  alt={section.about}
                  onClick={handleMenuItemClick}
                >
                  <HStack>
                    {section.icon}
                    <Text>{section.name}</Text>
                  </HStack>
                </MenuItem>
              ) : (
                <MenuItem
                  to={section.to}
                  alt={section.about}
                  onClick={handleMenuItemClick}
                  isLast
                >
                  <Button
                    size="lg"
                    rounded="md"
                    color={["primary.500", "primary.500", "white", "white"]}
                    bg={["white", "white", "primary.500", "primary.500"]}
                    _hover={{
                      bg: [
                        "primary.100",
                        "primary.100",
                        "primary.600",
                        "primary.600",
                      ],
                    }}
                  >
                    <HStack>
                      {section.icon}
                      <Text>{section.name}</Text>
                    </HStack>
                  </Button>
                </MenuItem>
              )
            )}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Header;
