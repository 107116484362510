import React from "react";
import { Heading, Stack, Flex } from "@chakra-ui/react";
import Description from "./description";

const BaseSection = ({ title, children, explanation, ...rest }) => (
  <Flex
    align="center"
    justify={{ base: "center", md: "space-around", xl: "space-between" }}
    direction="row"
    wrap="no-wrap"
    {...rest}
  >
    <Stack
      spacing={4}
      w={{ base: "85%", xl: "100%" }}
      align={["center", "center", "flex-start", "flex-start"]}
    >
      <Heading
        as="h1"
        size="xl"
        fontWeight="bold"
        color="primary.800"
        textAlign={["center", "center", "left", "left"]}
        mb={8}
      >
        {title}
      </Heading>
      {explanation && <Description>{explanation}</Description>}
      {children}
    </Stack>
  </Flex>
);

export default BaseSection;
