export const alegaciones = [
  "Necesidade de someter tamén a exposición pública o proxecto de execución das instalacións que finalmente se autorice e non só a RBDA. Durante a tramitación do expediente administrativo da LAT 66/220 kV SET Pico Touriñán-SET Tibo, e sobre todo durante o trámite ambiental, incorporouse nova documentación aportada polo promotor, relevante desde o punto de vista ambiental e que non foi obxecto de consulta para o público interesado.",
  "Ausencia da posta a disposición do público interesado da información relevante a efectos da decisión sobre a execución do proxecto obtida tras o trámite de exposición pública. Durante a tramitación ambiental do proxecto de execución das instalación incorporouse ao expediente administrativo documentación nova, aportada polo promotor, e diversos informes sectoriais relevantes e decisivos para a aprobación da Declaración de impacto ambiental.",
  "Ausencia da exposición pública dos documentos relevantes existentes no expedientes na data deste trámite. A páxina 5 da Declaración de impacto ambiental do proxecto, indica literalmente, no punto “2. TRAMITACIÓN. 2.1. Resumo da tramitación” o seguinte: “O 02.01.2012,  recíbese na Subdirección Xeral de Avaliación Ambiental o documento de inicio de avaliación ambiental para que a mesma determine se o proxecto debe someterse ou non a avaliación de impacto ambientala A subdirección determina o seu sometemento e, en data de 02.02.2012 comunícalle ao órgano substantivo o inicio do período de consultas para a determinación da amplitude e nivel de detalle do estudo de impacto ambiental a elaborar polo promotor, de acordo co artigo 8 do RDL 1/2008.",
  "Necesidade dun novo trámite de exposición pública, tras os defectos de tramitación apreciados durante a tramitación do expediente das instalacións da 66/220 kv, entre Set Parque Eólico Pico Touriñán-Set Tivo. Debera someterse de novo o proxecto de execución, o Estudo de impacto ambiental e a relación de bens e dereitos afectados a unha nova exposición, ao limitarse ou condicionarse a participación pública e do público interesado na toma de decisións relativas ao medio ambiente.",
  "A minimización e incorrecta avaliación ambiental do proxecto das instalacións da LAT Set Pico Touriñán -Set Tivo. Produciuse unha inadecuada avaliación dos impactos ambientais da infraestrutura de evacuación, ao tramitarse de forma independente dos proxectos eólicos dos que evacúa a enerxía.",
  "A insuficiencia do Estudo de Impacto Ambiental sometido ao trámite de exposición pública, que converte en “papel mollado” a participación do público e do público interesado. Tanto o público como as persoas afectadas non dispuxeron de información relativa aos parques eólicos que evacúan a súa enerxía na LAT SET PICO TOURIÑÁN -SET TIVO. A ausencia de información ao respecto, limita e condiciona a participación do público na avaliación dos impactos sinérxicos e acumulados das infraestruturas, o que deixa sen efecto e validez o citado estudo de sinerxías.",
];

export const introducciones = [
  "Á vista da RESOLUCIÓN do 17 de xaneiro de 2024, da Xefatura Territorial de Pontevedra, pola que se someten a información pública a modificación da solicitude de declaración de utilidade pública, en concreto, e a necesidade de urxente ocupación que iso implica, da liña de alta tensión 66/220 kV entre SET parque eólico Pico Touriñán-SET Tivo, nos concellos de Cerdedo-Cotobade, A Estrada, Campo Lameiro, Moraña, Portas e Caldas de Reis (expediente IN407A 2011/350)",
  "Á vista da RESOLUCIÓN do 17 de xaneiro de 2024, da Xefatura Territorial de Pontevedra, pola que se someten a información pública a modificación da solicitude de declaración de utilidade pública, en concreto, e a necesidade de urxente ocupación que iso implica, da liña de alta tensión 66/220 kV entre SET parque eólico Pico Touriñán-SET Tivo, nos concellos de Cerdedo-Cotobade, A Estrada, Campo Lameiro, Moraña, Portas e Caldas de Reis (expediente IN407A 2011/350), DOG Núm. 38, de 22 de febreiro de 2024, o abaixo firmante.",
  "En vista da RESOLUCIÓN do 17 de xaneiro de 2024, da Xefatura Territorial de Pontevedra, pola que se someten a información pública a modificación da solicitude de declaración de utilidade pública, en concreto, e a necesidade de urxente ocupación que iso implica, da liña de alta tensión 66/220 kV entre SET parque eólico Pico Touriñán-SET Tivo, nos concellos de Cerdedo-Cotobade, A Estrada, Campo Lameiro, Moraña, Portas e Caldas de Reis (expediente IN407A 2011/350), DOG Núm. 38, de 22 de febreiro de 2024",
  "Tendo en conta a RESOLUCIÓN do 17 de xaneiro de 2024, da Xefatura Territorial de Pontevedra, pola que se someten a información pública a modificación da solicitude de declaración de utilidade pública, en concreto, e a necesidade de urxente ocupación que iso implica, da liña de alta tensión 66/220 kV entre SET parque eólico Pico Touriñán-SET Tivo, nos concellos de Cerdedo-Cotobade, A Estrada, Campo Lameiro, Moraña, Portas e Caldas de Reis (expediente IN407A 2011/350), DOG Núm. 38, de 22 de febreiro de 2024, mediante este documento :",
];

export const solicitudes = [
  "1.- Sometan de novo ao trámite de exposición pública e consulta ao público interesado o proxecto de execución das instalacións, o estudo de impacto ambiental e a declaración de utilidade pública (RBDA e planos parcelarios), para subsanar os defectos de tramitación indicados na parte expositiva deste escrito de alegación e que limitaron e condicionaron a participación do público e do público interesado na toma de decisións relativas ao medio ambiente.\n\n2.- Acceso ao expediente administrativo da liña de alta tensión 66/220 kV entre SET parque eólico Pico Touriñán-SET Tivo, nos concellos de Cerdedo-Cotobade, A Estrada, Campo Lameiro, Moraña, Portas e Caldas de Reis (expediente IN407A 2011/350).",
];
