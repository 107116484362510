import { alegaciones, introducciones, solicitudes } from "./alegaciones";
import Allegations from "~/src/components/signatures/allegations";

export const AlegaZudreiro = () => (
  <Allegations
    polygonName="POLÍGONO EÓLICO ZUDREIRO (expediente IN408A 2019/46)"
    polygonShortName="Zudreiro"
    alegaciones={alegaciones}
    introducciones={introducciones}
    solicitudes={solicitudes}
  />
);
