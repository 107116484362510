export { Root } from "./root";
export { Zudreiro } from "./zudreiro";
export { Andurina } from "./andurina";
export { Acibal } from "./acibal";
export { Home } from "./home";
export { Map } from "./map";
export { Documentation } from "./documentation";
export { Videos } from "./videos";
export { HomeZudreiro } from "./zudreiro/home";
export { AlegaZudreiro } from "./zudreiro/alega";
export { HomeLatTourinhanTivo } from "./lat-tourinhan-tivo/home";
export { AlegaLatTourinhanTivo } from "./lat-tourinhan-tivo/alega";
export { Petroglyphs } from "./petroglyphs";
export { DogEolicos } from "./dog-eolicos";
export { PorquinhoSolidario } from "./porquinho-solidario";
export { Manifesto } from "./manifesto";
export { Gdpr } from "./gdpr";
export { GdprEn } from "./gdpr-en";
export { Signatures } from "./signatures";
export { Historia } from "./historia";
export { NovoMapa } from "./mapas/novo";
