import { Box, Text, UnorderedList, ListItem } from "@chakra-ui/react";

const ManifestoGl = () => (
  <Box textAlign="justify" fontFamily="Alegreya, serif">
    <Text fontSize="xl" mb="8">
      É un feito que o sector industrial perdeu moito peso en <b>Galicia</b> e
      que hai unha necesidade imperiosa de atraer novos investimentos e de
      buscar sectores de futuro que poidan traer riqueza e emprego á nosa terra.
      Pero estas necesidades non nos poden confundir, o desenvolvemento
      económico <b>debe ser planificado</b> e pensado de acordo a unha idea
      global de país, que recolla as grandes estratexias a seguir, e garantir
      que estes proxectos atendan a criterios de{" "}
      <b>sustentabilidade e de respecto co medio</b> no que se desenvolven.
    </Text>
    <Text fontSize="xl" mb="8">
      Porén, nos últimos anos e en aras da modernidade, da enerxía verde e do
      progreso, vénse lexislando sempre no sentido de facer primar a suposta
      utilidade pública e as iniciativas empresariais e <b>esquecendo</b> de
      forma sistemática{" "}
      <b>a protección do patrimonio natural, cultural e paisaxístico</b>. Esta
      tendencia podémola ver con total claridade nos textos das leis 5/2017 do
      19 de outubro coa declaración de utilidade pública dos parques eólicos e
      na Lei 9/2021 do 25 de febreiro de <b>simplificación administrativa</b> e
      de apoio á reactivación económica de Galicia.
    </Text>
    <Text fontSize="xl" mb="8">
      Estamos de acordo en que Galicia non pode nin debe renunciar ó progreso e
      ó desenvolvemento da súa economía. Pero paralelamente tamén temos a{" "}
      <b>obriga de preservar e protexer o patrimonio e os recursos naturais</b>.
      Entendemos que é un erro empeñar o patrimonio dun pobo, os seus recursos
      culturais, naturais e paisaxísticos en pos dunha aposta
      económico-empresarial tutelada de maneira tibia polos poderes públicos e
      case deseñada para maximizar os beneficios dos grandes lobbies e
      operadores enerxéticos.
    </Text>
    <Text fontSize="xl" mb="8">
      Mais as Administracións están sendo pouco esixentes coa preservación dos
      bens e recursos dos nosos montes onde se están a construír polígonos
      eólicos. Así acontece no caso do <b>Monte Acibal</b>, onde a veciñanza foi
      quen de atopar uns{" "}
      <b>50 achados entre petróglifos e elementos etnográficos</b> nunha zona
      que a empresa eólica case considera “estéril” arqueoloxicamente. Unha zona
      con afloramentos de granito de gran fino que son o substrato mais
      frecuente sobre o que foron gravados os petróglifos e tamén, pola súa
      orografía, as zonas con mais probabilidade de atopar, xa sexan inscricións
      rupestres, tetrasqueis, cruces medievais, chouzos e outros elementos
      patrimoniais, xa que dominan boa extensión do territorio.
    </Text>
    <Text fontSize="xl" mb="8">
      O patrimonio cultural e natural, o hídrico, gandeiro e forestal tamén son
      recursos económicos cando se explotan de xeito sostible.{" "}
      <b>Polígonos eólicos e liñas de alta tensión ameazan este patrimonio</b> e
      en concreto o dos montes de Campo Lameiro, a{" "}
      <b>Capital da Arte Rupestre</b> cunha das concentracións de petróglifos
      máis grandes de Europa. Os petróglifos son elementos arqueolóxicos que non
      se poden entender sen a paisaxe que os rodea e calquera modificación
      desta, ademais de poder alterar bens aínda non descubertos, cambiará unha
      paisaxe arqueolóxica <b>para sempre</b>, hipotecando a posibilidade dun
      desenvolvemento sostible dunha futura xestión cultural e turística destes
      recursos co beneficio económico que esta modalidade de turismo rural poida
      reportar ó territorio e o seu pobo.
    </Text>
    <Text fontSize="xl" mb="8">
      O mesmo acontece con diversos <b>hábitats</b> existentes neste monte, unha
      ampla zona húmida na meseta, amplísimas zonas en ladeira de produción de
      madeira, e unhas non tan pequenas áreas que se corresponden coas partes
      mais elevadas e con terreos moi abruptos que sempre foron redutos dos
      lobos e outros animais.
    </Text>
    <Text fontSize="xl" mb="8">
      Non se está a facer, polo tanto, unha oposición irresponsable á obtención
      de enerxía eólica. Estase a facer oposición ás maneiras de proceder das
      empresas que teñen a concesión <b>co beneplácito da Xunta de Galicia</b>.
    </Text>
    <Text fontSize="xl" mb="4">
      Por todo isto, os abaixo asinantes <b>solicitamos</b>:
    </Text>
    <UnorderedList textAlign="left" fontSize="xl" mb="8" ml="8">
      <ListItem mb="4">
        A <b>paralización das obras do Acibal</b> ante os achados e a destrución
        de posibles bens por falta dun estudo pormenorizado o que nos leva a
        solicitar unha moratoria na autorización de proxectos eólicos
        baseándonos no principio de precaución e de non regresión ambiental.
      </ListItem>
      <ListItem mb="4">
        Unha{" "}
        <b>revisión dos proxectos de macroeólicas en zonas arqueolóxicas</b>{" "}
        mellorando os instrumentos de avaliación ambiental e re-planificando o
        modelo de desenvolvemento económico de Galicia.
      </ListItem>
      <ListItem mb="4">
        Respectar a Soberanía dos Concellos que rexeitan a instalación de
        macroeólicos no seu territorio e <b>deter a discriminación</b> do medio
        rural.
      </ListItem>
      <ListItem mb="4">
        Maior Compromiso das Administracións coa posta en valor do{" "}
        <b>patrimonio</b> que atesouran.
      </ListItem>
      <ListItem mb="4">
        Necesidade de <b>conservación</b> dos espazos naturais para garantir a
        súa <b>biodiversidade</b>.
      </ListItem>
    </UnorderedList>
    <Text as="h3" fontSize="xl" mb="8" textAlign="center">
      <i>Moraña a 7 de Agosto de 2023</i>
    </Text>
  </Box>
);

export default ManifestoGl;
