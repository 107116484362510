import React from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
} from "@chakra-ui/react";

const TipBox = ({ tipTitle, children }) => {
  return (
    <Alert
      status="info"
      variant="subtle"
      flexDirection="column"
      alignItems="left"
      justifyContent="left"
      textAlign="left"
      boxShadow="md"
      rounded="md"
      p={8}
      mt="16"
      bg="yellow.100"
    >
      <Box display="flex" alignItems="center" justifyItems="left" mb="4">
        <AlertIcon boxSize="2em" mr={0} color="yellow.400" />
        <AlertTitle ml="4" fontSize="xl" color="yellow.600">
          {tipTitle}
        </AlertTitle>
      </Box>
      <AlertDescription fontSize="lg" color="yellow.800">
        {children}
      </AlertDescription>
    </Alert>
  );
};

export default TipBox;
