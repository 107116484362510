import React from "react";

const AllegationContent = ({ introduction, allegations, request }) => {
  return (
    <div>
      <p>{introduction}</p>
      <p>
        <strong>ALEGA:</strong>
      </p>
      <div class="allegations">
        <ol>
          {allegations.map((allegation) => (
            <li>{allegation}</li>
          ))}
        </ol>
      </div>
      <p>Polo tanto, SOLICITA:</p>
      <p>{request}</p>
    </div>
  );
};

export default AllegationContent;
